import { ICompany, ITemplateDocument, ISale, IShop } from "@lib";
import { IMacro, IMacroReplacer, ISaleMacroOpenContext } from "../macro";
import { SaleMacroList } from "./sale-document.macro-list";

export type SalePrintContext = {
    company: ICompany;
    shop: IShop;
    sale: ISale;
};

export class SaleDocumentMacroReplacer implements IMacroReplacer<ITemplateDocument> {
    // %(макрос:аргумент1,аргумент2)
    private readonly regexp = /%\((?<macro>[\p{L}\p{N}.]*)(:?(?<args>[\p{L}\p{N}.,]*)?)\)/giu;
    // \p{L} - юникодные буквы
    // \p{N} - юникодные цифры
    // регулярки: https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
    // юникодные символы: https://learn.javascript.ru/regexp-unicode
    // проверка регулярок: https://regex101.com/

    private readonly context: SalePrintContext;

    public constructor(context: SalePrintContext) {
        this.context = context;
    }

    public replace(model: ITemplateDocument): string {
        return this.replaceSimple(model.template, model);
    }

    public replaceSimple(template: string, model?: ITemplateDocument): string {
        return this.replaceRegexp(template, value => value);
    }

    protected replaceRegexp(template: string, func: (value: string) => string): string {
        return template.replace(this.regexp, (match: string, macro: string, args: string) => {
            try {
                const argsArray = args.length > 0 ? args.substring(1).split(",") : undefined;
                const value = this.replaceMacro(macro, argsArray) ?? match;
                return func(value);
            } catch {
                //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });
    }

    private replaceMacro(macroName: string, args?: string[]): string | null {
        const macro = this.findMacro(macroName);
        if (!macro) {
            return null;
        }

        const sale = this.context.sale;

        const context: ISaleMacroOpenContext = {
            company: this.context.company,
            shop: this.context.shop,
            sale: sale,
            client: sale.clientRef,
            seller: sale.sellerRef,
        };

        return macro.open(context, args) ?? "";
    }

    private findMacro(macroName: string): IMacro<ISaleMacroOpenContext> | null {
        macroName = macroName.toLowerCase();

        for (const macro of SaleMacroList) {
            for (const alias of macro.alias) {
                if (alias.toLowerCase() === macroName) {
                    return macro;
                }
            }
        }

        return null;
    }
}

export class SaleDocumentMacroHtmlReplacer extends SaleDocumentMacroReplacer {
    public constructor(context: SalePrintContext) {
        super(context);
    }

    public replaceSimple(template: string, model?: ITemplateDocument): string {
        return this.replaceRegexp(template, value => `<span style="white-space: pre-wrap;">${value}</span>`);
    }
}
