import { IStore } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Collapse, Control, FileUpload, Html, Select } from "@core/components/alt-ui/controls";

export type GoodsImportModalContext = {
    stores: IStore[];
    storeId: string | null;
    importHandler: (dto: GoodsImportModalResult) => Promise<boolean>;
};

export type GoodsImportModalResult = { storeId: string; file?: any };

export class GoodsImportModal extends Modal<GoodsImportModalContext, boolean | undefined> {
    private cbStore!: Select<IStore>;
    private fuFile!: FileUpload;
    private htmlHelp!: Html;
    private clHelp!: Collapse;
    private btnImport!: Button;

    private context?: GoodsImportModalContext;

    public constructor() {
        super("modal-goods-import", "Импорт товаров");
        this.initializeControls();
    }

    public show(context: GoodsImportModalContext): Promise<boolean | undefined> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.cbStore = new Select<IStore>();
        this.cbStore.id = "goods.import.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = s => s.info.name;
        this.cbStore.descriptionField = s => s.info.description;
        this.cbStore.selectedIndex = this.cbStore.items.length > 0 ? 0 : -1;

        this.fuFile = new FileUpload();
        this.fuFile.id = "goods.import.file";
        this.fuFile.label = "Укажите CSV-файл";
        this.fuFile.placeholder = "Выберите или перетащите файл";
        this.fuFile.accept = ".csv";

        const linkExample = require("@/assets/documents/import-goods.csv");

        this.htmlHelp = new Html();
        this.htmlHelp.html = `<small>Для импорта принимаются файлы формата CSV:<br/>
            <br/>
            - Информация по каждому товару на новой строке.<br/>
            - Первая строка - заголовки столбцов.<br/>
            - Кодировка файла - UTF-8.<br/>
            - Файл не должен превышать 5 МБ.<br/>
            <br/>
            <a href="${linkExample}" download="import-goods.csv" class="hover:underline">Скачать пример CSV-файла</a></small>`;

        this.clHelp = new Collapse();
        this.clHelp.id = "goods.import.help";
        this.clHelp.title = "Справка";
        this.clHelp.opened = true;
        this.clHelp.addControl(this.htmlHelp);

        this.btnImport = new Button();
        this.btnImport.id = "goods.import.import";
        this.btnImport.text = "Импорт";
        this.btnImport.class = "mt-0.25";
        this.btnImport.addClickHandler(this.clickBtnImport.bind(this));
    }

    public get controls(): Control[] {
        return [this.cbStore, this.fuFile, this.clHelp, this.btnImport];
    }

    private async clickBtnImport(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid || !this.context?.importHandler || !this.cbStore.selectedItem) {
            return;
        }

        const fileControl = document.getElementById("goods.import.file") as any;

        const result: GoodsImportModalResult = {
            storeId: this.cbStore.selectedItem.id,
            file: fileControl?.files?.length ? fileControl.files[0] : undefined,
        };

        const ok = await this.context.importHandler(result);

        if (ok) {
            this.hide(true);
        }
    }
}
