import { IGoodCategory, IStore, ITableFilter, Locale, ReadQueryFilter } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, CheckBox, Control, MultiSelect, Panel } from "@core/components/alt-ui/controls";
import { NumericRangeControl } from "@core/controls/numeric-range.control";
import { Filter } from "@/utils/filter";
import { QueryConverter } from "@/utils/plugins/query-converter";
import { Localizer } from "@/i18n/localizer";

export type GoodsExportModalContext = {
    stores: IStore[];
    storeIds: string[];
    categories: IGoodCategory[];
    exportHandler: (dto: GoodsExportModalResult) => Promise<boolean>;
};

export type GoodsExportModalResult = { filter?: ReadQueryFilter };

export class GoodsExportModal extends Modal<GoodsExportModalContext, boolean | undefined> {
    private chSetupFilters!: CheckBox;

    private pnFilter!: Panel;
    private msStore!: MultiSelect<IStore>;
    private msCategory!: MultiSelect;
    private nrCost!: NumericRangeControl;
    private nrPrice!: NumericRangeControl;
    private nrQuantity!: NumericRangeControl;

    private btnExport!: Button;

    private context?: GoodsExportModalContext;

    public constructor() {
        super("modal-goods-export", "Экспорт товаров");
        this.initializeControls();
    }

    public show(context: GoodsExportModalContext): Promise<boolean | undefined> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.chSetupFilters = new CheckBox();
        this.chSetupFilters.id = "goods.export.checkbox";
        this.chSetupFilters.text = "Настроить фильтр";
        this.chSetupFilters.class = "mt-0.25";

        this.initializeFilterPanel();

        this.btnExport = new Button();
        this.btnExport.id = "goods.export.export";
        this.btnExport.text = "Экспорт";
        this.btnExport.class = "mt-0.25";
        this.btnExport.addClickHandler(this.onClick.bind(this));
    }

    public initializeFilterPanel(): void {
        this.msStore = new MultiSelect<IStore>();
        this.msStore.id = "goods.export.filter.store";
        this.msStore.label = "Склад";
        this.msStore.items = this.context?.stores ?? [];
        this.msStore.textField = ac => ac.info.name;
        this.msStore.descriptionField = ac => ac.info.description;
        this.msStore.selectedItems = this.msStore.items.filter(s => this.context?.storeIds.includes(s.id));

        this.msCategory = new MultiSelect();
        this.msCategory.id = "goods.filter.category";
        this.msCategory.label = "Категории товара";
        this.msCategory.items = this.context?.categories ?? [];
        this.msCategory.textField = (opt: IGoodCategory) => opt.name;
        this.msCategory.descriptionField = (opt: IGoodCategory) => opt.description;

        this.nrCost = new NumericRangeControl({
            id: "goods.export.filter.cost",
            title: "Себестоимость",
            validation: "money|unsigned",
        });

        this.nrPrice = new NumericRangeControl({
            id: "goods.export.filter.price",
            title: "Цена продажи",
            validation: "money|unsigned",
        });

        this.nrQuantity = new NumericRangeControl({
            id: "goods.export.filter.quantity",
            title: "Количество",
            validation: "numeric",
        });

        this.pnFilter = new Panel();
        this.pnFilter.class = "space-y-2";
        this.pnFilter.addControls([this.msStore, this.msCategory, this.nrCost, this.nrPrice, this.nrQuantity]);
    }

    public get controls(): Control[] {
        if (this.chSetupFilters.value) {
            return [this.chSetupFilters, this.pnFilter, this.btnExport];
        }

        return [this.chSetupFilters, this.btnExport];
    }

    private async onClick(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid || !this.context?.exportHandler) {
            return;
        }

        const filter = this.extractFilterValues();
        const ok = await this.context.exportHandler(filter ? { filter } : {});

        if (ok) {
            this.hide(true);
        }
    }

    private extractFilterValues(): null | ReadQueryFilter {
        if (!this.chSetupFilters.value) {
            return null;
        }

        const tableFilter: ITableFilter[] = [];
        const locale = Locale.RU;

        tableFilter.push(
            ...Filter.convertInBack(
                "store",
                this.msStore.selectedItems.map(e => e.id),
            ),
        );
        tableFilter.push(
            ...Filter.convertInBack(
                "category",
                this.msCategory.selectedItems.map(e => e.id),
            ),
        );
        tableFilter.push(
            ...Filter.convertBetweenBack("cost", [
                this.nrCost.value[0] ? Localizer.number(parseFloat(this.nrCost.value[0]), locale) : "",
                this.nrCost.value[1] ? Localizer.number(parseFloat(this.nrCost.value[1]), locale) : "",
            ]),
        );
        tableFilter.push(
            ...Filter.convertBetweenBack("price", [
                this.nrPrice.value[0] ? Localizer.number(parseFloat(this.nrPrice.value[0]), locale) : "",
                this.nrPrice.value[1] ? Localizer.number(parseFloat(this.nrPrice.value[1]), locale) : "",
            ]),
        );
        tableFilter.push(
            ...Filter.convertBetweenBack("quantity", [this.nrQuantity.value[0] ?? "", this.nrQuantity.value[1] ?? ""]),
        );

        const queryConverter = new QueryConverter();

        return queryConverter.tableFilterToQueryFilter(tableFilter) ?? null;
    }
}
