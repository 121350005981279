import { ITemplateDocument } from "@lib";
import { IMacroReplacer, ITableIterableMacroOpenReturn } from "../macro";

type MacroMatch = { macro: string; args: string[] | undefined };

export abstract class Table2MacroReplacer implements IMacroReplacer<ITemplateDocument> {
    private readonly macroList: any[];
    private readonly iterableMacroList: any[];

    public constructor(macroList: any[], iterableMacroList: any[]) {
        this.macroList = macroList;
        this.iterableMacroList = iterableMacroList;
    }

    public abstract getIterableContext(iterator: number): any;

    public abstract getContext(): any;

    public processIterableRow = (parent: HTMLElement, row: HTMLTableRowElement): any => {
        const cells = row.querySelectorAll("td");
        const cellsContent = [...cells].map(cell => cell.textContent ?? "");

        let flag = true;
        let iterator = 0;

        while (flag) {
            const clonedRow = row.cloneNode(true) as HTMLTableRowElement;
            const clonedCells = clonedRow.querySelectorAll("td");

            for (let index = 0; index < cellsContent.length; ++index) {
                const cellContent = cellsContent[index];
                const openedMacro = this.replaceMacroFromTemplate(cellContent, iterator);
                flag = openedMacro.hasNextRow;
                clonedCells[index].innerHTML = openedMacro.value;
            }

            if ([...clonedCells].some(e => e.innerHTML)) {
                this.insertChildAtIndex(parent, clonedRow, iterator + 1);
            }

            iterator++;
        }
    };

    public hasIterableMacro(arr: string[]): boolean {
        const iterableMacroses = this.iterableMacroList.reduce((a, macro) => a.concat(macro.alias), [] as string[]);

        const macroses: MacroMatch[] = [];
        for (const item of arr) {
            const macroMatches = this.getMacroFromTemplate(item);
            macroses.push(...macroMatches);
        }

        if (macroses.some(e => iterableMacroses.includes(e.macro))) {
            return true;
        }

        return false;
    }

    private getMacroFromTemplate(template: string): MacroMatch[] {
        // %(макрос:аргумент1,аргумент2)
        const regexp = /%\((?<macro>[\p{L}\p{N}.-]*)(:?(?<args>[\p{L}\p{N}.,]*)?)\)/giu;
        // \p{L} - юникодные буквы
        // \p{N} - юникодные цифры
        // регулярки: https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
        // юникодные символы: https://learn.javascript.ru/regexp-unicode
        // проверка регулярок: https://regex101.com/

        const macroMatches: MacroMatch[] = [];

        // https://javascript.info/regexp-groups#named-groups
        const results = template.matchAll(regexp);

        if (results) {
            for (const result of results) {
                if (!result.groups) {
                    continue;
                }

                const macro = result.groups.macro;
                const argsStr = result.groups.args;
                const args = argsStr && argsStr.length > 0 ? argsStr.split(",") : undefined;
                macroMatches.push({ macro, args });
            }
        }

        return macroMatches;
    }

    private replaceMacroFromTemplate(template: string, iterator: number): ITableIterableMacroOpenReturn {
        // %(макрос:аргумент1,аргумент2)
        const regexp = /%\((?<macro>[\p{L}\p{N}.-]*)(:?(?<args>[\p{L}\p{N}.,]*)?)\)/giu;
        // \p{L} - юникодные буквы
        // \p{N} - юникодные цифры
        // регулярки: https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
        // юникодные символы: https://learn.javascript.ru/regexp-unicode
        // проверка регулярок: https://regex101.com/

        let hasNextRow = false;

        const value = template.replace(regexp, (match: string, macro: string, args: string) => {
            try {
                const argsArray = args.length > 0 ? args.substring(1).split(",") : undefined;
                const openedMacro = this.openIterableMacro(macro, argsArray, iterator);
                hasNextRow = openedMacro.hasNextRow;
                return openedMacro.value.toString();
            } catch {
                //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });

        return { hasNextRow, value };
    }

    public getIterableRow(rows: HTMLTableRowElement[]): HTMLTableRowElement | undefined {
        return rows.find(row => {
            const cells = row.querySelectorAll("td");
            const cellsContent = [...cells].map(cell => cell.textContent ?? "");

            if (!this.hasIterableMacro(cellsContent)) {
                return false;
            }

            return true;
        });
    }

    public replace(model: ITemplateDocument): string {
        return this.replaceSimple(model.template, model);
    }

    public replaceSimple(template: string, model?: ITemplateDocument): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(template, "text/html");
        const tables = doc.getElementsByTagName("table");

        for (const table of tables) {
            let num = 0;
            const rows = table.getElementsByTagName("tr");

            const iterableRow = this.getIterableRow([...rows]);

            if (iterableRow) {
                const parent = iterableRow.parentElement as HTMLElement;
                const row = iterableRow.cloneNode(true) as HTMLTableRowElement;

                parent.removeChild(iterableRow);

                this.processIterableRow(parent, row);
            }

            for (let rindex = 0; rindex < rows.length; ++rindex) {
                const row = rows[rindex];

                const nullable = this.isNullableRow(row);
                if (nullable) {
                    const data = { num, index: rindex };
                    const removed = this.processNullableRow(row, data);

                    if (removed) {
                        rindex -= 1;
                    } else {
                        num += 1;
                    }
                    continue;
                }

                const data = { num, index: rindex };
                this.processNormalRow(row, data);
                num += 1;
            }
        }

        return doc.documentElement.innerHTML;
    }

    private isNullableRow(row: HTMLTableRowElement): boolean {
        const cells = row.getElementsByTagName("td");
        for (const cell of cells) {
            if (this.isNullableCell(cell.innerText)) {
                return true;
            }
        }
        return false;
    }

    private isNullableCell(content: string): boolean {
        const regexp = /%\(\?[\p{L}\p{N}.-]*\)/giu;
        return regexp.test(content);
    }

    private processNullableRow(row: HTMLTableRowElement, data: any): boolean {
        let nullable = false;

        const cells = row.getElementsByTagName("td");
        for (const cell of cells) {
            const regexp = /%\((?<macro>\?[\p{L}\p{N}.-]*)\)/giu;

            const match = regexp.exec(cell.innerHTML);
            if (!match || !match.groups) {
                continue;
            }

            const macro = match.groups.macro.slice(1);

            const openedMacro = this.openMacro(macro);

            if (!openedMacro || ["0", "0.0", "0,00"].includes(openedMacro)) {
                nullable = true;
                break;
            }

            cell.innerHTML = cell.innerHTML.replace(regexp, (match: string, macro: string) => {
                try {
                    return openedMacro;
                } catch {
                    // console.warn(`Не удалось раскрыть макрос: ${match}.`);
                    return match;
                }
            });
        }

        if (nullable) {
            const parent = row.parentElement;
            if (!parent) {
                throw new Error("Parent is not found.");
            }

            parent.removeChild(row);
        }

        return nullable;
    }

    private processNormalRow(row: HTMLTableRowElement, data: any): void {
        const cells = row.getElementsByTagName("td");
        for (const cell of cells) {
            const regexp = /%\((?<macro>[\p{L}\p{N}.-]*)\)/giu;

            cell.innerHTML = cell.innerHTML.replace(regexp, (match: string, macro: string) => {
                try {
                    return this.openMacro(macro) ?? match;
                } catch {
                    //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                    return match;
                }
            });
        }
    }

    public openIterableMacro(
        macro: string,
        args: string[] | undefined,
        iterator: number,
    ): ITableIterableMacroOpenReturn {
        const context = this.getIterableContext(iterator);

        const currentMacro = this.iterableMacroList.find(e => e.alias.includes(macro.toLowerCase()));

        if (!currentMacro || !currentMacro.open) {
            return {
                hasNextRow: false,
                value: macro,
            };
        }

        return currentMacro.open(context);
    }

    private openMacro(macro: string): string | null {
        const context = this.getContext();

        for (const item of this.macroList) {
            for (const alias of item.alias) {
                if (alias.toLowerCase() === macro.toLowerCase()) {
                    return item.open(context) ?? "";
                }
            }
        }

        return null;
    }

    private insertChildAtIndex(elem: Element, child: Element, index: number): void {
        // https://stackoverflow.com/questions/5882768/how-to-append-a-childnode-to-a-specific-position

        if (index >= elem.children.length) {
            elem.appendChild(child);
        } else {
            elem.insertBefore(child, elem.children[index]);
        }
    }
}
