import { IStore, IGood, IGoodCreateDto, IGoodUpdateDto, IGoodCategory, ITemplateLabel, ICompany, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    Control,
    Dropdown,
    Icon,
    MultiSelect,
    Panel,
    Select,
    Tags,
    TextArea,
    TextBox,
} from "@core/components/alt-ui/controls";
import { WarrantyControl } from "@core/controls/warranty.control";
import { Localizer } from "@/i18n/localizer";
import { BarcodeControl } from "@core/controls/barcode/barcode-control";
import { StringUtils } from "@/utils/string.utils";

export interface IGoodModalContext {
    company: ICompany;
    stores: IStore[];
    selectedStores: IStore[];
    /** Все категории, включая удалённые. */
    categories: IGoodCategory[];
    showBarcode: boolean;
    templates?: ITemplateLabel[];

    /** Товар для редактирования. */
    good?: IGood;

    /** Обработчик создания товара. */
    createHandler?: (storeId: string, dto: IGoodCreateDto) => Promise<boolean>;

    /** Обработчик обновления товара. */
    updateHandler?: (good: IGood, dto: IGoodUpdateDto) => Promise<boolean>;

    /** Обработчик кнопки просмотра истории товара. */
    historyHandler?: (good: IGood) => Promise<void>;

    /** Обработчик кнопки перемещения товара. */
    moveHandler?: (good: IGood) => Promise<void>;

    /** Обработчик кнопки печати ценников для товара. */
    printHandler?: (template: ITemplateLabel, good: IGood) => Promise<void>;
}

interface IToolsItem {
    id: string;
    name: string;
    description?: string;
    icon?: string;
    click?: () => void | Promise<void>;
}

export class GoodModal extends Modal<IGoodModalContext, boolean> {
    private bcBarcode!: BarcodeControl;
    private сbStore!: Select<IStore>;
    private tbSku!: TextBox;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private msCategory!: MultiSelect<IGoodCategory>;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private tbQuantityThreshold!: TextBox;
    private wcWarranty!: WarrantyControl;
    private tgTags!: Tags;

    private pnlFooterCreate!: Panel;
    private pnlFooterEdit!: Panel;
    private pnlButtonsWrapper!: Panel;

    private ddTools!: Dropdown<IToolsItem>;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context?: IGoodModalContext;

    public constructor() {
        super("good-modal", "");
        this.initializeControls();
    }

    public show(context: IGoodModalContext): Promise<boolean> {
        this.context = context;
        this.title = context.good ? "Изменение товара" : "Новый товар";
        this.initializeControls();

        if (context.good) {
            this.populateControls(context.good);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.bcBarcode = new BarcodeControl({
            companyId: this.context?.company.id ?? "",
        });
        this.сbStore = new Select<IStore>();
        this.tbSku = new TextBox();
        this.tbName = new TextBox();
        this.taDescription = new TextArea();
        this.msCategory = new MultiSelect<IGoodCategory>();
        this.tbCost = new TextBox();
        this.tbPrice = new TextBox();
        this.tbQuantity = new TextBox();
        this.tbQuantityThreshold = new TextBox();
        this.wcWarranty = new WarrantyControl();
        this.tgTags = new Tags();

        this.btnCancel = new Button();
        this.btnSave = new Button();

        this.pnlFooterEdit = new Panel();
        this.pnlFooterCreate = new Panel();
        this.pnlButtonsWrapper = new Panel();

        //

        this.bcBarcode.visible = this.context?.showBarcode ?? false;

        this.сbStore.id = "good.store";
        this.сbStore.label = "Склад";
        this.сbStore.items = this.context?.stores ?? [];
        this.сbStore.textField = ac => ac.info.name;
        this.сbStore.descriptionField = ac => ac.info.description;
        this.сbStore.selectedIndex = this.сbStore.items.length > 0 ? 0 : -1;

        this.tbSku.id = "good.sku";
        this.tbSku.label = "Артикул";
        this.tbSku.text = "";

        this.tbName.id = "good.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.taDescription.id = "good.description";
        this.taDescription.label = "Описание";
        this.taDescription.text = "";

        this.msCategory.id = "good.category";
        this.msCategory.label = "Категории";
        this.msCategory.items = this.getAllowedCategories();
        this.msCategory.textField = e => e.name;
        this.msCategory.descriptionField = e => e.description;

        this.tbCost.id = "good.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.text = "";

        this.tbPrice.id = "good.price";
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";
        this.tbPrice.text = "";

        this.tbQuantity.id = "good.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned";
        this.tbQuantity.text = "";

        this.tbQuantityThreshold.id = "good.quantityThreshold";
        this.tbQuantityThreshold.label = "Минимальный остаток";
        this.tbQuantityThreshold.help = "Количество товара, показывающее, что необходимо заказывать ещё";
        this.tbQuantityThreshold.validation = "numeric|unsigned";
        this.tbQuantityThreshold.text = "";

        this.wcWarranty.id = "good.warranty";
        this.wcWarranty.label = "Гарантия";

        this.tgTags.id = "good.tags";
        this.tgTags.label = "Метки";
        this.tgTags.placeholder = "Введите новую метку";

        //

        const tools: IToolsItem[] = [];

        if (this.context?.historyHandler) {
            tools.push({
                id: "good-tools.history",
                name: "История",
                icon: "ClockIcon",
                click: () => this.clickHistory(),
            });
        }

        if (this.context?.moveHandler) {
            tools.push({
                id: "good-tools.move",
                name: "Переместить",
                icon: "ShareIcon",
                click: () => this.clickMove(),
            });
        }

        if (this.context?.printHandler && this.context.templates && this.context.templates.length > 0) {
            for (const template of this.context.templates) {
                tools.push({
                    id: "good-tools.move",
                    name: `Печать ${template.name}`,
                    icon: "PrinterIcon",
                    click: () => this.clickPrint(template),
                });
            }
        }

        this.ddTools = new Dropdown<IToolsItem>();
        this.ddTools.id = "good-tools";
        this.ddTools.hideCaret = true;
        this.ddTools.variant = "flat-primary";
        this.ddTools.text = "Действия";
        this.ddTools.items = tools;
        this.ddTools.textField = i => i.name;
        this.ddTools.iconField = i => i.icon;
        this.ddTools.addClickHandler((s, e) => e.item?.click?.());

        this.btnCancel.id = "good.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "good.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        if (this.context?.good) {
            this.pnlButtonsWrapper.addControl(this.btnCancel);
            this.pnlButtonsWrapper.addControl(this.btnSave);

            this.pnlFooterEdit.class = "flex justify-between mt-2";
            this.pnlFooterEdit.addControl(this.ddTools);
            this.pnlFooterEdit.addControl(this.pnlButtonsWrapper);
        } else {
            this.pnlFooterCreate.class = "flex justify-end mt-2";
            this.pnlFooterCreate.addControl(this.btnCancel);
            this.pnlFooterCreate.addControl(this.btnSave);
        }
    }

    private populateControls(good: IGood): void {
        const locale = good.storeRef?.info.locale ?? Locale.RU;

        // В context.categories лежат все категории, в т.ч и удалённые
        // Мы ищем в них потому что у пользователя мог остаться сохраненный фильтр
        // А сама категория была удалена
        const selectedCategories =
            this.context?.categories.filter(c => good.info.categories?.find(id => c.id === id)) ?? [];

        this.bcBarcode.barcodes = good.info.barcodes ?? [];
        this.сbStore.visible = false;
        this.tbSku.text = good.info.sku ?? "";
        this.tbName.text = good.info.name;
        this.taDescription.text = good.info.description ?? "";
        this.msCategory.selectedItems = selectedCategories;
        this.tbCost.text = Localizer.number(good.info.cost, locale);
        this.tbPrice.text = Localizer.number(good.info.price, locale);
        this.tbQuantity.text = good.info.quantity.toString();
        this.tbQuantityThreshold.text = good.quantityThreshold?.toString() ?? "";
        this.wcWarranty.value = good.info.warranty;
        this.tgTags.tags = good.tags ?? [];
    }

    public get controls(): Control[] {
        const common = [
            this.bcBarcode,
            this.сbStore,
            this.tbSku,
            this.tbName,
            this.taDescription,
            this.msCategory,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.tbQuantityThreshold,
            this.wcWarranty,
            this.tgTags,
        ] as Control[];

        if (this.context?.good) {
            return common.concat([this.pnlFooterEdit]);
        }

        return common.concat([this.pnlFooterCreate]);
    }

    private getAllowedCategories(): IGoodCategory[] {
        if (!this.context?.good || !this.context?.categories) {
            return [];
        }

        const storeId = this.context.good?.storeRef?.id;
        const categories = this.context.categories.filter(e => !e.archived);

        return categories.filter(c => (c.stores as IStore[]).some(s => s.id === storeId) || !c.stores.length);
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide(false);
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const ok = this.context?.good ? await this.updateGood(this.context.good) : await this.createGood();

        if (ok) {
            this.hide(true);
        }
    }

    private async createGood(): Promise<boolean> {
        if (!this.context?.createHandler || !this.сbStore.selectedItem) {
            return false;
        }

        const storeId = this.сbStore.selectedItem.id;

        const dto: IGoodCreateDto = {
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: StringUtils.moneyStringToNumber(this.tbCost.text),
                price: StringUtils.moneyStringToNumber(this.tbPrice.text),
                quantity: StringUtils.integerStringToNumber(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
            tags: this.tgTags.tags,
        };

        if (this.tbQuantityThreshold.text.length !== 0) {
            dto.quantityThreshold = StringUtils.integerStringToNumber(this.tbQuantityThreshold.text);
        }

        return await this.context.createHandler(storeId, dto);
    }

    private async updateGood(good: IGood): Promise<boolean> {
        if (!this.context?.updateHandler) {
            return false;
        }

        const dto: IGoodUpdateDto = {
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: StringUtils.moneyStringToNumber(this.tbCost.text),
                price: StringUtils.moneyStringToNumber(this.tbPrice.text),
                quantity: StringUtils.integerStringToNumber(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
            tags: this.tgTags.tags,
        };

        if (this.tbQuantityThreshold.text.length !== 0) {
            dto.quantityThreshold = StringUtils.integerStringToNumber(this.tbQuantityThreshold.text);
        }

        return await this.context.updateHandler(good, dto);
    }

    private async clickHistory(): Promise<void> {
        if (!this.context?.historyHandler || !this.context.good) {
            return;
        }

        this.context.historyHandler(this.context.good);
        this.hide();
    }

    private async clickMove(): Promise<void> {
        if (!this.context?.moveHandler || !this.context.good) {
            return;
        }

        this.context.moveHandler(this.context.good);
        this.hide();
    }

    private clickPrint(template: ITemplateLabel): void {
        if (!this.context?.printHandler || !this.context.good) {
            return;
        }

        this.context.printHandler(template, this.context.good);
        this.hide();
    }
}
