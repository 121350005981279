import { Button, Control, Panel } from "@core/components/alt-ui/controls";
import { Modal } from "@core/components/alt-ui/modal";
import { FilterInfo, IFilter, IFilterContext } from ".";

export class FilterModal<TContext extends IFilterContext> extends Modal<TContext, boolean | undefined> {
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context?: TContext;
    private filters: IFilter[] = [];

    public constructor(id: string, title: string = "Фильтр") {
        super(id, title);
        this.initializeCommon();
    }

    public show(context: TContext): Promise<boolean | undefined> {
        this.context = context;
        this.filters = context.filters;
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.initializeCommon();

        if (!this.context) {
            return;
        }

        for (const filter of this.filters) {
            filter.init(filter, this.context);
        }
    }

    private initializeCommon(): void {
        this.btnCancel = new Button();
        this.btnCancel.id = `${this.id}.footer.cancel`;
        this.btnCancel.text = "Отменить";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = `${this.id}.footer.save`;
        this.btnSave.class = "mr-0.75";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "mt-2";
        this.pnlFooter.addControls([this.btnSave, this.btnCancel]);
    }

    public get controls(): Control[] {
        const controls = this.filters.map(f => f.control) ?? [];
        return [...controls, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide(false);
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid || !this.context || !this.context.saveHandler) {
            return;
        }

        this.context.filters = this.filters;

        const result = new FilterInfo<TContext>();
        result.setContext(this.context);

        const ok = await this.context.saveHandler(result);

        if (ok) {
            this.hide(true);
        }
    }
}
