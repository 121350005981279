import { Modal } from "@core/components/alt-ui/modal";
import { Button, Badge, Control, Html, Icon, List, Panel, Label } from "@core/components/alt-ui/controls";
import router, { MainPages } from "@/router/router";
import { GoodHistoryItemType, IEmployee, IGood, IGoodHistoryItem, ISelectedData } from "@lib";
import * as filters from "@/filters";
import { Formatter } from "@/utils/formatter";

export interface IGoodHistoryModalContext {
    good: IGood;
    employees: IEmployee[];
    loadHandler: (offset: number, limit: number) => Promise<ISelectedData<IGoodHistoryItem> | null>;
}

export class GoodHistoryModal extends Modal<IGoodHistoryModalContext, void> {
    private listItems!: List<IGoodHistoryItem>;
    private lbNotFound!: Label;
    private btnLoadMore!: Button;

    private btnClose!: Button;
    private pnlFooter!: Panel;

    private context?: IGoodHistoryModalContext;
    private total = 1;
    private limit = 10;

    public constructor() {
        super("good-history-modal", "История товара");
        this.initializeControls();
    }

    public show(context: IGoodHistoryModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        this.load();
        return super.show();
    }

    protected initializeControls(): void {
        this.listItems = new List<IGoodHistoryItem>();
        this.listItems.id = "good-history-list";
        this.listItems.class = "mt-1";
        this.listItems.items = [];
        this.listItems.header = item => this.getTitle(item);
        this.listItems.content = item => {
            const content = new Html();
            content.id = `good-history-content-${item.id}`;
            content.class = "mt-0.5";
            content.html = this.getContent(item);
            return content;
        };
        this.listItems.right = item => {
            const btnLink = new Button();
            btnLink.id = `good-history-open-${item.id}`;
            btnLink.help = "Перейти";
            btnLink.variant = "flat-primary";
            btnLink.icon = new Icon();
            btnLink.icon.icon = "ExternalLinkIcon";
            btnLink.addClickHandler((s, e) => this.open(item));

            // TODO: rights

            return btnLink;
        };
        this.listItems.footer = item => {
            return filters.datetime.datetime(item.datetime, "L LT");
        };

        this.lbNotFound = new Label();
        this.lbNotFound.id = "good-history-not-found";
        this.lbNotFound.text = "Ничего не найдено";
        this.lbNotFound.class = "mt-1";

        this.btnLoadMore = new Button();
        this.btnLoadMore.id = "good-history-load-more";
        this.btnLoadMore.class = "w-100";
        this.btnLoadMore.text = "Загрузить ещё";
        this.btnLoadMore.variant = "flat-primary";
        this.btnLoadMore.addClickHandler(() => this.load());

        this.btnClose = new Button();
        this.btnClose.id = "good-history-close";
        this.btnClose.text = "Закрыть";
        this.btnClose.variant = "outline-danger";
        this.btnClose.addClickHandler(() => this.hide());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnClose);
    }

    private getTitle(item: IGoodHistoryItem): string {
        switch (item.type) {
            case GoodHistoryItemType.Registration:
                return `Оприходование № ${item.meta?.registrationNumber}`;
            case GoodHistoryItemType.WriteOff:
                return `Списание № ${item.meta?.writeoffNumber}`;
            case GoodHistoryItemType.OrderMaterial:
                return `Заявка № ${item.meta?.orderNumber}`;
            case GoodHistoryItemType.Sale:
                return `Продажа № ${item.meta?.saleNumber}`;
        }

        return "";
    }

    private getContent(item: IGoodHistoryItem): string {
        const employee = this.context?.employees.find(e => e.user === item.userId);

        switch (item.type) {
            case GoodHistoryItemType.Registration:
                return `
                    <div>Количество: ${item.meta?.quantity}</div>
                    <div>Себестоимость: ${Formatter.money(item.meta?.cost)}</div>
                    <div>Сотрудник: ${employee?.userRef?.info?.name}</div>
                    `;
            case GoodHistoryItemType.WriteOff:
                return `
                    <div>Количество: ${item.meta?.quantity}</div>
                    <div>Себестоимость: ${Formatter.money(item.meta?.cost)}</div>
                    <div>Сотрудник: ${employee?.userRef?.info?.name}</div>
                    `;
            case GoodHistoryItemType.OrderMaterial:
                return `
                    <div>Количество: ${item.meta?.quantity}</div>
                    <div>Себестоимость: ${Formatter.money(item.meta?.cost)}</div>
                    <div>Цена продажи: ${Formatter.money(item.meta?.price)}</div>
                    <div>Сотрудник: ${employee?.userRef?.info?.name}</div>
                    `;
            case GoodHistoryItemType.Sale:
                return `
                    <div>Количество: ${item.meta?.quantity}</div>
                    <div>Себестоимость: ${Formatter.money(item.meta?.cost)}</div>
                    <div>Цена продажи: ${Formatter.money(item.meta?.price)}</div>
                    <div>Сотрудник: ${employee?.userRef?.info?.name}</div>
                    `;
        }

        return "";
    }

    public get controls(): Control[] {
        return [this.listItems.items.length === 0 ? this.lbNotFound : this.listItems, this.btnLoadMore, this.pnlFooter];
    }

    private async load(): Promise<void> {
        if (!this.context?.loadHandler || this.listItems.items.length >= this.total) {
            return;
        }

        const result = await this.context.loadHandler(this.listItems.items.length, this.limit);

        if (!result) {
            return;
        }

        this.listItems.items.push(...result.data);
        this.total = result.total;

        this.btnLoadMore.visible = this.listItems.items.length < this.total;
    }

    private open(item: IGoodHistoryItem): void {
        let routeData: any;

        switch (item.type) {
            case GoodHistoryItemType.Registration:
                routeData = router.resolve({
                    name: MainPages.StoreGoodRegistrations,
                    query: { id: item.meta?.registrationId },
                });
                break;
            case GoodHistoryItemType.WriteOff:
                routeData = router.resolve({
                    name: MainPages.StoreGoodWriteOffs,
                    query: { id: item.meta?.writeoffId },
                });
                break;
            case GoodHistoryItemType.OrderMaterial:
                routeData = router.resolve({ name: "orders", query: { id: item.meta?.orderId } });
                break;
            case GoodHistoryItemType.Sale:
                routeData = router.resolve({ name: "sales", query: { id: item.meta?.saleId } });
                break;
        }

        if (routeData?.href) {
            window.open(routeData.href, "_blank");
        }
    }
}
