import { ITableFilter, ReadQueryFilter } from "@lib";
import { Control } from "@core/components/alt-ui/controls";
import { ObjectUtils } from "@/utils/object.utils";
import { AppException } from "@/core/exceptions";

export interface IFilterContext {
    filters: IFilter[];
    tfilters: ITableFilter[];
    saveHandler?: (filterInfo: FilterInfo) => Promise<boolean>;
}

export enum FilterType {
    Equals = "eq",
    In = "in",
    Between = "btw",
}

export interface IFilter<TControl extends Control = any, TContext extends IFilterContext = any> {
    id: string;
    type: FilterType;
    control: TControl;
    init: (self: IFilter<TControl>, context: TContext) => void;
    getValue: (self: IFilter<TControl>, context: TContext) => any;
    setValue: (self: IFilter<TControl>, context: TContext, value: any) => void;
    getTableFilter: (self: IFilter<TControl>, context: TContext) => ITableFilter[];
    getSelectQueryFilter: (self: IFilter<TControl>, context: TContext) => Record<string, any>;
    getReadQueryFilter: (self: IFilter<TControl>, context: TContext) => ReadQueryFilter;
}

export class FilterInfo<TContext extends IFilterContext = any> {
    private context?: TContext;
    private filters: IFilter[] = [];

    public init(context: TContext): void {
        this.context = context;
        this.filters = context.filters;

        for (const filter of this.filters) {
            filter.init(filter, this.context);
        }
    }

    public setContext(context: TContext): void {
        this.context = context;
        this.filters = context.filters;
    }

    public getValue(id: string): any {
        const filter = this.filters.find(f => f.id === id);
        return filter ? filter.getValue(filter, this.context) : null;
    }

    public setValue(id: string, value: any): void {
        const filter = this.filters.find(f => f.id === id);

        if (filter) {
            filter.setValue(filter, this.context, value);
        }
    }

    public getObject(): Record<string, any> {
        if (!this.context) {
            throw new AppException("Context is not specified.");
        }

        const object: Record<string, any> = {};

        for (const filter of this.filters) {
            if (!filter.control.visible) {
                continue;
            }

            object[filter.id] = filter.getValue(filter, this.context);
        }

        return object;
    }

    public getTableFilter(): ITableFilter[] {
        if (!this.context) {
            throw new AppException("Context is not specified.");
        }

        const table: ITableFilter[] = [];

        for (const filter of this.filters) {
            if (!filter.control.visible) {
                continue;
            }

            const tfilter = filter.getTableFilter(filter, this.context);
            table.push(...tfilter);
        }

        return table;
    }

    public getSelectQueryFilter(): Record<string, any> {
        if (!this.context) {
            throw new AppException("Context is not specified.");
        }

        let selectQuery: Record<string, any> = {};

        for (const filter of this.filters) {
            if (!filter.control.visible) {
                continue;
            }

            const squery = filter.getSelectQueryFilter(filter, this.context);
            selectQuery = ObjectUtils.mergeDeep(selectQuery, squery);
        }

        return selectQuery;
    }

    public getReadQueryFilter(): ReadQueryFilter {
        if (!this.context) {
            throw new AppException("Context is not specified.");
        }

        let readQuery: ReadQueryFilter = {};

        for (const filter of this.filters) {
            if (!filter.control.visible) {
                continue;
            }

            const rquery = filter.getReadQueryFilter(filter, this.context);
            readQuery = ObjectUtils.mergeDeep(readQuery, rquery);
        }

        return readQuery;
    }
}

export * from "./filter-factory";
export * from "./filter.modal";
