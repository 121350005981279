import { IStore, IGood, IGoodMoveDto } from "@lib";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Control, Label, Select, TextBox } from "@core/components/alt-ui/controls";
import { Footer } from "@core/controls/footer.control";
import { StringUtils } from "@/utils/string.utils";

export interface IGoodMoveModalContext {
    good: IGood;
    stores: IStore[];
    moveHandler: (dto: IGoodMoveDto) => Promise<boolean>;
}

export class GoodMoveModal extends Modal2<IGoodMoveModalContext, boolean | undefined> {
    private lbTitle!: Label;
    private cbStore!: Select<IStore>;
    private tbQuantity!: TextBox;
    private lbQuantityInfo!: Label;
    private ftFooter!: Footer;

    private context?: IGoodMoveModalContext;

    public constructor() {
        super("good-move-modal", "Перемещение товара");
        this.initializeControls();
    }

    public show(context: IGoodMoveModalContext): Promise<boolean | undefined> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.lbTitle = new Label();
        this.lbTitle.id = "good-move.title";

        this.cbStore = new Select<IStore>();
        this.cbStore.id = "good-move.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = s => s.info.name;
        this.cbStore.descriptionField = s => s.info.description;
        this.cbStore.selectedIndex = this.cbStore.items.length > 0 ? 0 : -1;

        this.tbQuantity = new TextBox();
        this.tbQuantity.id = "good-move.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.text = "1";

        this.lbQuantityInfo = new Label();
        this.lbQuantityInfo.id = "good-move.quantity-info";

        this.ftFooter = new Footer({
            okText: "Переместить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(context: IGoodMoveModalContext): void {
        this.lbTitle.text = `Перемещение товара: ${context.good.info.name}`;

        this.cbStore.items = context.stores.filter(e => e.id !== context.good.store);
        this.cbStore.selectedIndex = this.cbStore.items.length > 0 ? 0 : -1;
        this.cbStore.selectedItem = this.cbStore.items[this.cbStore.selectedIndex];

        this.tbQuantity.validation = `required|numeric|unsigned|min_value:1|max_value:${context.good.info.quantity}`;

        this.lbQuantityInfo.text = `Всего на складе: ${context.good.info.quantity}`;
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    public get controls(): Control[] {
        return [this.lbTitle, this.cbStore, this.tbQuantity, this.lbQuantityInfo];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide(false);
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid || !this.context?.moveHandler || !this.cbStore.selectedItem) {
            return;
        }

        const dto: IGoodMoveDto = {
            quantity: StringUtils.integerStringToNumber(this.tbQuantity.text),
            storeId: this.cbStore.selectedItem.id,
        };

        const ok = await this.context.moveHandler(dto);

        if (ok) {
            this.hide(true);
        }
    }
}
