import { IStore, IGoodCreateDto, IGoodCategory, Locale, IBarcode, BarcodeType } from "@lib";
import { Control, MultiSelect, TextArea, TextBox } from "@core/components/alt-ui/controls";
import { WarrantyControl } from "@core/controls/warranty.control";
import { Localizer } from "@/i18n/localizer";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Footer } from "@core/controls/footer.control";
import { BarcodeControl } from "@core/controls/barcode/barcode-control";
import { StringUtils } from "@/utils/string.utils";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GoodRegistrationCreateItemModalContext {
    companyId: string;
    good?: IGoodCreateDto; // товар для редактирования
    stores: IStore[];
    selectedStore: IStore;
    categories: IGoodCategory[];
    showBarcode: boolean;
    selectedType?: BarcodeType;
    generateBarcode: () => Promise<IBarcode | null>;
    updateBarcodeType: (type: BarcodeType) => Promise<void>;
}

export class GoodRegistrationCreateItemModal extends Modal2<GoodRegistrationCreateItemModalContext> {
    private bcBarcode!: BarcodeControl;
    private tbSku!: TextBox;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private msCategory!: MultiSelect<IGoodCategory>;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private tbQuantityThreshold!: TextBox;
    private wcWarranty!: WarrantyControl;

    private ftFooter!: Footer;

    private context!: GoodRegistrationCreateItemModalContext;
    private categories!: IGoodCategory[];
    public onCreate: ((dto: IGoodCreateDto) => Promise<boolean | null>) | null = null;
    public onUpdate: ((orig: IGoodCreateDto, dto: IGoodCreateDto) => Promise<boolean | null>) | null = null;

    public constructor() {
        super("good-registration-create-item-modal", "");
        this.initializeControls();
    }

    public get footer(): Footer {
        return this.ftFooter;
    }

    public show(context: GoodRegistrationCreateItemModalContext): Promise<void> {
        this.context = context;
        this.categories = this.context.categories.filter(e => !e.archived);

        this.title = context.good ? "Изменение товара" : "Новый товар";

        this.initializeControls();

        if (context.good) {
            this.populateControls(context.good);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.bcBarcode = new BarcodeControl({
            companyId: this.context?.companyId ?? "",
            selectedType: this.context?.selectedType,
            onChangeType: this.context?.updateBarcodeType,
        });
        this.tbSku = new TextBox();
        this.tbName = new TextBox();
        this.taDescription = new TextArea();
        this.msCategory = new MultiSelect<IGoodCategory>();
        this.tbCost = new TextBox();
        this.tbPrice = new TextBox();
        this.tbQuantity = new TextBox();
        this.tbQuantityThreshold = new TextBox();
        this.wcWarranty = new WarrantyControl();

        //

        if (this.context?.showBarcode) {
            this.generateBarcode();
        }
        this.bcBarcode.visible = this.context?.showBarcode ?? false;

        this.tbSku.id = "good.sku";
        this.tbSku.label = "Артикул";
        this.tbSku.text = "";

        this.tbName.id = "good.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.taDescription.id = "good.description";
        this.taDescription.label = "Описание";
        this.taDescription.text = "";

        this.msCategory.id = "good.category";
        this.msCategory.label = "Категории";
        this.msCategory.items = this.getAllowedCategories();
        this.msCategory.textField = e => e.name;
        this.msCategory.descriptionField = e => e.description;

        this.tbCost.id = "good.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.text = "";

        this.tbPrice.id = "good.price";
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";
        this.tbPrice.text = "";

        this.tbQuantity.id = "good.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned";
        this.tbQuantity.text = "";

        this.tbQuantityThreshold.id = "good.quantityThreshold";
        this.tbQuantityThreshold.label = "Минимальный остаток";
        this.tbQuantityThreshold.help = "Количество товара, показывающее, что необходимо заказывать ещё";
        this.tbQuantityThreshold.validation = "numeric|unsigned";
        this.tbQuantityThreshold.text = "";

        this.wcWarranty.id = "good.warranty";
        this.wcWarranty.label = "Гарантия";

        //

        this.ftFooter = new Footer({
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(good: IGoodCreateDto): void {
        const locale = this.context.selectedStore?.info.locale ?? Locale.RU;
        //const locale = good.storeRef?.info.locale ?? Locale.RU;

        // В context.categories лежат все категории, в т.ч и удалённые
        // Мы ищем в них потому что у пользователя мог остаться сохраненный фильтр
        // А сама категория была архивирован
        const categories = good.info.categories
            ?.map(categoryId => this.context.categories.find(e => e.id === categoryId) ?? null)
            .filter(e => e) as IGoodCategory[];

        this.bcBarcode.barcodes = good.info.barcodes ?? [];
        this.tbSku.text = good.info.sku ?? "";
        this.tbName.text = good.info.name;
        this.taDescription.text = good.info.description ?? "";
        this.msCategory.selectedItems = categories;
        this.tbCost.text = Localizer.number(good.info.cost, locale);
        this.tbPrice.text = Localizer.number(good.info.price, locale);
        this.tbQuantity.text = good.info.quantity.toString();
        this.tbQuantityThreshold.text = good.quantityThreshold?.toString() ?? "";
        this.wcWarranty.value = good.info.warranty;
    }

    public get controls(): Control[] {
        return [
            this.bcBarcode,
            this.tbSku,
            this.tbName,
            this.taDescription,
            this.msCategory,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.tbQuantityThreshold,
            this.wcWarranty,
        ];
    }

    private async generateBarcode(): Promise<void> {
        if (!this.context) {
            return;
        }

        const barcode = await this.context.generateBarcode();
        if (barcode) {
            this.bcBarcode.barcodes = [barcode];
        }
    }

    private getAllowedCategories(): IGoodCategory[] {
        if (!this.context || !this.categories) {
            return [];
        }

        const storeId = this.context.selectedStore.id;

        return this.categories.filter(
            e => (e.stores as IStore[]).some(store => store.id === storeId) || !e.stores.length,
        );
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.context.good ? await this.updateGood(this.context.good) : await this.createGood();

        if (result) {
            this.hide(result);
        }
    }

    private async createGood(): Promise<boolean | null> {
        if (!this.onCreate) {
            return null;
        }

        const goodDto: IGoodCreateDto = {
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: StringUtils.moneyStringToNumber(this.tbCost.text),
                price: StringUtils.moneyStringToNumber(this.tbPrice.text),
                quantity: StringUtils.integerStringToNumber(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
        };

        if (this.tbQuantityThreshold.text.length !== 0) {
            goodDto.quantityThreshold = StringUtils.integerStringToNumber(this.tbQuantityThreshold.text);
        }

        return await this.onCreate(goodDto);
    }

    private async updateGood(orig: IGoodCreateDto): Promise<boolean | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IGoodCreateDto = {
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: StringUtils.moneyStringToNumber(this.tbCost.text),
                price: StringUtils.moneyStringToNumber(this.tbPrice.text),
                quantity: StringUtils.integerStringToNumber(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
        };

        if (this.tbQuantityThreshold.text.length !== 0) {
            dto.quantityThreshold = StringUtils.integerStringToNumber(this.tbQuantityThreshold.text);
        }

        return await this.onUpdate(orig, dto);
    }
}
