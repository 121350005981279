import {
    IAccount,
    ICompany,
    ICompanyFeatureBarcodes,
    IGood,
    IGoodCategory,
    IGoodCreateDto,
    IGoodDeleteManyDto,
    IGoodHistoryItem,
    IGoodMoveDto,
    IGoodMoveManyDto,
    IGoodRegistration,
    IGoodUpdateDto,
    IReadQuery,
    ISelectedData,
    ISettingsTableUpdateDto,
    IStore,
    ISupplier,
    ITable,
    ITableColumn,
    ITableFilter,
    ITableSort,
    ITemplateLabel,
    IUser,
    ReadQueryFilter,
    TableType,
    TemplateLabelType,
} from "@lib";
import { Printer } from "@core/usecases/template/printer";
import { GoodPrintContext } from "@core/usecases/template/macro-replacers/good-label.macro-replacer";
import { TableApi } from "@/core/components/alt-table";
import { AltPlugin, InfoPlugin, SettingsPlugin } from "@/utils/plugins";
import { Formatter } from "@/utils/formatter";
import { AppException } from "@/core/exceptions";

/** Данные таблицы. */
export type TableData = {
    api: TableApi;
    type: TableType;
    page: number;
    search: string;
    settings: ITable | null;
};

/** Загрузчик данных для раздела Склад. */
export class StoresDataLoader {
    private _company: ICompany | null = null;
    private _user: IUser | null = null;
    private _stores: IStore[] | null = null;

    private get company(): ICompany {
        if (!this._company) {
            throw new AppException("Загрузчик данных не был проинициализирован.");
        }

        return this._company;
    }

    private get user(): IUser {
        if (!this._user) {
            throw new AppException("Загрузчик данных не был проинициализирован.");
        }

        return this._user;
    }

    private get stores(): IStore[] {
        if (!this._stores) {
            throw new AppException("Загрузчик данных не был проинициализирован.");
        }

        return this._stores;
    }

    public constructor(
        private readonly $alt: AltPlugin,
        private readonly $info: InfoPlugin,
        private readonly $settings: SettingsPlugin,
    ) {}

    public async init(): Promise<void> {
        this._company = await this.$info.getCompany();
        this._user = await this.$info.getUser();
        this._stores = await this.$info.getStores();
    }

    /** Загрузить настройки дополнительной функции "Штрихкоды". */
    public async getBarcodesFeature(): Promise<ICompanyFeatureBarcodes | null> {
        try {
            return await this.$alt.system.usecase.createCompanyFeatureBarcodesUseCase().get(this.company.id);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        }
    }

    /** Загрузить склады, доступные пользователю. */
    public async selectStores(): Promise<IStore[]> {
        try {
            const stores = await this.$alt.system.usecase
                .createEmployeeUseCase()
                .selectStores(this.company.id, this.user.id);
            this.$info.setStores(stores);

            // const filterStores = this.filter.filter(item => item.field === "store");
            // this.selectedStores = this.stores.filter(s => !!filterStores.find(f => f.value === s.id));

            return stores;
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить склады:\n${e.message}`);
        }
    }

    /** Загрузить ценники. */
    public async selectLabelTemplates(): Promise<ITemplateLabel[]> {
        try {
            return await this.$alt.system.usecase
                .createTemplateLabelUseCase()
                .select(this.company.id, TemplateLabelType.GoodLabel);
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить ценники:\n${e.message}`);
        }
    }

    /** Загрузить все категории, включая удалённые. */
    public async selectAllCategories(): Promise<IGoodCategory[]> {
        try {
            return await this.$alt.system.usecase.createGoodCategoryUseCase().selectAll(this.company.id);
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить категории:\n${e.message}`);
        }
    }

    /** Загрузить поставщиков. */
    public async selectSuppliers(): Promise<ISupplier[]> {
        try {
            return await this.$alt.system.usecase.createSupplierUseCase().select(this.company.id);
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить поставщиков:\n${e.message}`);
        }
    }

    /** Загрузить настройки таблицы. */
    public async selectTableSettings(type: TableType): Promise<ITable | null> {
        try {
            // TODO: если настроек нет, то возвращается null.
            // Если ошибка - бросается исключение.
            return await this.$alt.system.usecase.createSettingsTableUseCase().get(this.company.id, this.user.id, type);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - колонки. */
    public async updateTableColumns(type: TableType, columns: ITableColumn[]): Promise<ITable | null> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - сортировку. */
    public async updateTableSort(type: TableType, sort: ITableSort[]): Promise<ITable | null> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - количество элементов. */
    public async updateTableLimit(type: TableType, limit: number): Promise<ITable | null> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - фильтр. */
    public async updateTableFilter(type: TableType, filter: ITableFilter[]): Promise<ITable> {
        try {
            const dto: ISettingsTableUpdateDto = { filter };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось сохранить настройки фильтра:\n${e.message}`);
        }
    }

    /** Загрузить товары. */
    public async selectGoodsForTable(table: TableData): Promise<ISelectedData<IGood>> {
        try {
            const limit = table.settings?.limit ?? TableApi.DefaultLimit;
            const offset = (table.page - 1) * limit;
            const tsort = table.settings?.sort;
            const tfilter = table.settings?.filter;
            const search = table.search;

            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const query: IReadQuery = { limit, offset, sort, search, filter };
            return await this.$alt.system.usecase.createGoodUseCase().select(this.company.id, query);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить товары:\n${e.message}`);
        }
    }

    /** Загрузить товары. */
    public async selectGoods(
        limit: number,
        offset: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
        search?: string,
    ): Promise<ISelectedData<IGood>> {
        try {
            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const query: IReadQuery = { limit, offset, sort, search, filter };
            return await this.$alt.system.usecase.createGoodUseCase().select(this.company.id, query);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить товары:\n${e.message}`);
        }
    }

    /** Загрузить данные товара. */
    public async getGood(id: string): Promise<IGood> {
        try {
            return await this.$alt.system.usecase.createGoodUseCase().get(this.company.id, id);
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить данные товара:\n${e.message}`);
        }
    }

    /** Загрузить данные товара. */
    public async getGoodHistotry(id: string, offset: number, limit: number): Promise<ISelectedData<IGoodHistoryItem>> {
        try {
            const query: IReadQuery = { offset, limit };
            return await this.$alt.system.usecase.createGoodUseCase().getHistory(this.company.id, id, query);
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить историю товара:\n${e.message}`);
        }
    }

    /** Поиск товаров. */
    public async searchGoods(search: string, storeId: string): Promise<IGood[]> {
        try {
            const query: IReadQuery = {
                search,
                limit: 10,
                // offset: 0,
                // filter: {
                //     quantity: { gt: 0 },
                //     store: { eq: storeId },
                //     store: { in: [storeId] },
                // },
            };
            const result = await this.$alt.system.usecase
                .createGoodUseCase()
                .selectForStore(this.company.id, storeId, query);

            return result.data;
        } catch {
            return [];
        }
    }

    /** Создать товар. */
    public async createGood(storeId: string, goodDto: IGoodCreateDto): Promise<void> {
        try {
            await this.$alt.system.usecase.createGoodUseCase().create(this.company.id, storeId, goodDto);
        } catch (e: any) {
            throw new AppException(`Не удалось создать товар:\n${e.message}`);
        }
    }

    /** Изменить товар. */
    public async updateGood(good: IGood, dto: IGoodUpdateDto): Promise<void> {
        try {
            await this.$alt.system.usecase.createGoodUseCase().update(this.company.id, good.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось изменить товар:\n${e.message}`);
        }
    }

    /** Переместить товар. */
    public async moveGood(good: IGood, dto: IGoodMoveDto): Promise<void> {
        try {
            await this.$alt.system.usecase.createGoodUseCase().move(this.company.id, good.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось переместить товар:\n${e.message}`);
        }
    }

    /** Переместить несколько товаров. */
    public async moveGoods(dto: IGoodMoveManyDto): Promise<void> {
        try {
            await this.$alt.system.usecase.createGoodUseCase().moveMany(this.company.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось переместить товары:\n${e.message}`);
        }
    }

    /** Удалить товар. */
    public async deleteGood(good: IGood): Promise<void> {
        try {
            await this.$alt.system.usecase.createGoodUseCase().delete(this.company.id, good.id);
        } catch (e: any) {
            throw new AppException(`Не удалось удалить товар:\n${e.message}`);
        }
    }

    /** Удалить несколько товаров. */
    public async deleteGoods(goods: IGood[]): Promise<void> {
        try {
            const dto: IGoodDeleteManyDto = {
                ids: goods.map(g => g.id),
            };

            await this.$alt.system.usecase.createGoodUseCase().deleteMany(this.company.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось удалить товары:\n${e.message}`);
        }
    }

    /** Импортировать товары. */
    public async importGoods(storeId: string, file: any): Promise<void> {
        try {
            const tag = `import_${Formatter.now({ format: "YYYYMMDD_HHmmss" })}`;
            const dto = { tags: [tag] };
            await this.$alt.system.usecase.createGoodUseCase().import(this.company.id, storeId, file, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось импортировать товары:\n${e.message}`);
        }
    }

    /** Экспортировать товары. */
    public async exportGoods(filter?: ReadQueryFilter): Promise<void> {
        try {
            const dto: IReadQuery = { filter };
            const file = await this.$alt.system.usecase.createGoodUseCase().export(this.company.id, dto);

            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([file]));
            link.download = `export_${Formatter.now({ format: "YYYYMMDD_HHmmss" })}.csv`;
            link.click();
            setTimeout(() => URL.revokeObjectURL(link.href), 0);
        } catch (e: any) {
            throw new AppException(`Не удалось экспортировать товары:\n${e.message}`);
        }
    }

    /** Сохранить настройку - выбранные ценники для печати. */
    public async saveSelectedLabels(labels: ITemplateLabel[]): Promise<void> {
        try {
            const value = labels.map(d => d.id);
            await this.$settings.setLabelsGoodRegistrationCreate(value);
        } catch {}
    }

    /** Сохранить настройку - нужно ли вычитать со счёта стоимость оприходования. */
    public async saveSelectedDeductFromAccount(status: boolean): Promise<void> {
        try {
            await this.$settings.setDeductFromAccountGoodRegistrationCreate(status);
        } catch {}
    }

    /** Сохранить настройку - выбранный счёт для вычитания стоимости оприходования. */
    public async saveSelectedAccount(account: IAccount): Promise<void> {
        try {
            await this.$settings.setAccountGoodRegistrationCreate(account.id);
        } catch {}
    }

    /** Печатать ценники для оприходования. */
    public printLabelsForGoodRegistration(labels: ITemplateLabel[], registration: IGoodRegistration): void {
        const goods = registration.goods.map(g => {
            const good = this.$alt.clone(g.goodRef as IGood);
            good.info.quantity = g.quantity;
            good.info.cost = g.cost;
            return good;
        });

        const context: GoodPrintContext = {
            company: this.company,
            stores: this.stores,
            goods: goods,
        };

        Printer.printGoodLabels(labels, context);
    }

    /** Печатать ценники для товаров. */
    public async printLabelsForGoods(label: ITemplateLabel, goods: IGood[]): Promise<void> {
        const context: GoodPrintContext = {
            company: this.company,
            stores: this.stores,
            goods: goods,
        };

        Printer.printGoodLabel(label, context);
    }
}
