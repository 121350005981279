import { ITableFilter, ReadQueryFilter } from "@lib";
import { MultiSelect } from "@core/components/alt-ui/controls";
import { NumericRangeControl } from "@core/controls/numeric-range.control";
import { FilterType, IFilter, IFilterContext } from ".";
import { Filter } from "@/utils/filter";
import { Uuid } from "@/utils/uuid";

/** Фабрика стандартных фильтров. */
export abstract class FilterFactory {
    /** Создать фильтр выбора нескольких значений. */
    public static createInFilter<T, TContext extends IFilterContext>(
        id: string,
        title: string,
        getItems: (context: TContext) => T[],
        getId: (item: T) => string,
        getText: (item: T) => string,
    ): IFilter {
        return {
            id: id,
            type: FilterType.In,
            control: new MultiSelect<T>(),
            init: (self, context): void => {
                const ids = Filter.convertIn(self.id, context.tfilters);

                self.control.id = Uuid.new();
                self.control.label = title;
                self.control.textField = getText;
                self.control.items = getItems(context);

                self.setValue(self, context, ids);
            },
            getValue: (self, context): any => {
                return self.control.selectedItems.map(getId);
            },
            setValue: (self, context, value): void => {
                const ids = value;
                self.control.selectedItems = self.control.items.filter(s => ids.includes(getId(s)));
            },
            getTableFilter: (self, context): ITableFilter[] => {
                return Filter.convertInBack(self.id, self.control.selectedItems.map(getId));
            },
            getSelectQueryFilter: (self, context): Record<string, any> => {
                const ids = self.control.selectedItems.map(getId);
                return Filter.inToSelectQueryFilter(self.id, ids);
            },
            getReadQueryFilter: (self, context): ReadQueryFilter => {
                const ids = self.control.selectedItems.map(getId);
                return Filter.inToReadQueryFilter(self.id, ids);
            },
        } as IFilter<MultiSelect<T>, TContext>;
    }

    /** Создать фильтр выбора значений от и до. */
    public static createBetweenFilter<TContext extends IFilterContext>(
        id: string,
        title: string,
        validation: string,
    ): IFilter {
        return {
            id: id,
            type: FilterType.Between,
            control: new NumericRangeControl({ id: Uuid.new(), title, validation }),
            init: (self, context): void => {
                const value = Filter.convertBetween(self.id, context.tfilters);
                self.setValue(self, context, value);
            },
            getValue: (self, context): any => {
                return self.control.value;
            },
            setValue: (self, context, value): void => {
                self.control.value = value;
            },
            getTableFilter: (self, context): ITableFilter[] => {
                return Filter.convertBetweenBack(self.id, self.control.value);
            },
            getSelectQueryFilter: (self, context): Record<string, any> => {
                return Filter.betweenToSelectQueryFilter(self.id, self.control.value);
            },
            getReadQueryFilter: (self, context): ReadQueryFilter => {
                return Filter.betweenToReadQueryFilter(self.id, self.control.value);
            },
        } as IFilter<NumericRangeControl, TContext>;
    }
}
