



























import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { IStore, IGoodCategory } from "@lib";

@Component({
    name: "goods-category-filter",
    components: { BButton, VuePerfectScrollbar },
})
export default class GoodsCategoryFilter extends Vue {
    @Prop({ type: Array, required: true })
    private categories!: IGoodCategory[];

    @Prop({ type: Array, default: [] })
    private selectedCategories!: IGoodCategory[];

    @Prop({ type: Array, required: true })
    private selectedStores!: IStore[];

    public get allowedCategories(): IGoodCategory[] {
        const selectedStores = this.selectedStores.map(e => e.id);

        return (
            this.categories?.filter(
                e => (e.stores as IStore[]).some(s => selectedStores.includes(s.id)) || !e.stores.length,
            ) ?? []
        );
    }

    public get filteredIds() {
        return this.selectedCategories.map(c => c.id);
    }

    public onClickAll() {
        this.$emit("select-all");
    }

    public onClick(id: string) {
        this.$emit("select", id);
    }
}
