import { IMacroReplacer } from "./macro";

export interface IPrintOptions<T> {
    getTemplate: (model: T) => string;
    pageWidth?: number;
}

export class DocumentPrinter<T> {
    private readonly macroReplacers: IMacroReplacer<T>[];

    public constructor(macroReplacers: IMacroReplacer<T>[]) {
        this.macroReplacers = macroReplacers;
    }

    public preview(models: T | T[], options: IPrintOptions<T>): void {
        const html = this.getPreviewHtml(models, options);
        this.openPreviewWindow(html, options);
    }

    public getPreviewHtml(models: T | T[], options: IPrintOptions<T>): string {
        const body = Array.isArray(models)
            ? this.replaceMacrosForModels(models, options)
            : this.replaceMacrosForModel(models, options);

        const css = this.getCommonCss({ padded: true });
        return this.getCommonHtml(css, body);
    }

    public print(models: T | T[], options: IPrintOptions<T>): void {
        const html = this.getPrintHtml(models, options);
        this.openPrintWindow(html);
    }

    public getPrintHtml(models: T | T[], options: IPrintOptions<T>): string {
        const body = Array.isArray(models)
            ? this.replaceMacrosForModels(models, options)
            : this.replaceMacrosForModel(models, options);

        const css = this.getCommonCss();
        return this.getCommonHtml(css, body);
    }

    public replaceMacrosForModel(model: T, options: IPrintOptions<T>): string {
        const template = options.getTemplate(model);
        return this.replaceMacrosForTemplate(template, model);
    }

    public replaceMacrosForModels(models: T[], options: IPrintOptions<T>): string {
        let print = "";

        for (const model of models) {
            if (print.length > 0) {
                print += '<p style="page-break-before: always"></p>';
            }

            print += this.replaceMacrosForModel(model, options);
        }

        return print;
    }

    public replaceMacrosForTemplate(template: string, model?: T): string {
        for (const replacer of this.macroReplacers) {
            template = replacer.replaceSimple(template, model);
        }

        return template;
    }

    private openPreviewWindow(html: string, previewOptions?: IPrintOptions<T>): void {
        const windowWidth = window.screen.availWidth;
        const windowHeight = window.screen.availHeight;
        const width = previewOptions?.pageWidth ?? 795; // 1cm = 37.8px

        const params = {
            width: windowWidth < width ? windowWidth : width,
            height: windowHeight,
        };

        const win = window.open("", "_blank", `scrollbars=yes,width=${params.width},height=${params.height}`);
        if (!win) {
            return;
        }

        win.document.write(html);
        win.document.close();
    }

    private openPrintWindow(html: string): void {
        const win = window.open("", "_blank", "fullscreen=yes,titlebar=yes,scrollbars=yes");
        if (!win) {
            return;
        }

        win.document.write(html);

        setTimeout(() => {
            win.addEventListener("afterprint", () => {
                win.close();
            });

            win.document.close();
            win.focus();
            win.print();
        }, 750);
    }

    private getCommonHtml(css: string, body: string): string {
        return `
<html>
<head>
    <title></title>
</head>
<style type="text/css">
    ${css}
</style>
<body>
    ${body}
</body>
</html>
        `;
    }

    private getCommonCss(config?: { padded?: boolean }): string {
        let styles = `
            body { margin: 0; }
            p { padding: 0; margin: 0px; line-height: 1; }
            ul, ol { line-height: 1; }
            li { padding: 0; margin: 0px; line-height: 1; }
        `;

        if (config?.padded) {
            styles += `
                body { padding: 8px }
            `;
        }

        return styles;
    }
}
