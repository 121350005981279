import { IGoodCategory, IStore, ITableFilter, ReadQueryFilter } from "@lib";
import { CheckBox } from "@core/components/alt-ui/controls";
import { FilterFactory, FilterType, IFilter, IFilterContext } from "@/core/alt-forms/filter";
import { StringUtils } from "@/utils/string.utils";

export interface IGoodsFilterContext extends IFilterContext {
    filters: IFilter[];
    tfilters: ITableFilter[];
    stores: IStore[];
    categories: IGoodCategory[];
}

export const createFilters = (): IFilter[] => {
    return [
        FilterFactory.createInFilter<IStore, IGoodsFilterContext>(
            "store",
            "Склад",
            context => context.stores,
            s => s.id,
            s => s.info.name,
        ),
        FilterFactory.createInFilter<IGoodCategory, IGoodsFilterContext>(
            "category",
            "Категории",
            context => context.categories,
            c => c.id,
            c => c.name,
        ),
        FilterFactory.createBetweenFilter<IGoodsFilterContext>("cost", "Себестоимость", "money|unsigned"),
        FilterFactory.createBetweenFilter<IGoodsFilterContext>("price", "Цена продажи", "money|unsigned"),
        FilterFactory.createBetweenFilter<IGoodsFilterContext>("quantity", "Количество", "numeric"),
        {
            id: "goodsForPurchase",
            type: FilterType.Equals,
            control: new CheckBox(),
            init: (self, context): void => {
                const value = !!context.tfilters.find(tf => tf.field === "quantityThreshold" && tf.operator === "gte");

                self.control.id = "goods-filter.goodsForPurchase";
                self.control.text = "Товары на закупку";
                // self.control.addValueChangedHandler((s, e) => {
                //     const filter = context.filters.find(f => f.id === "quantity");

                //     if (filter) {
                //         filter.control.visible = !e.value;
                //     }
                // });

                self.setValue(self, context, value);
            },
            getValue: (self, context): any => {
                return self.control.value;
            },
            setValue: (self, context, value): void => {
                self.control.value = StringUtils.stringToBoolean(value) ?? false;
            },
            getTableFilter: (self, context): ITableFilter[] => {
                if (!self.control.value) {
                    return [];
                }

                return [
                    {
                        field: "quantityThreshold",
                        operator: "gte",
                        value: "$quantity",
                    },
                ];
            },
            getSelectQueryFilter: (self, context): Record<string, any> => {
                if (!self.control.value) {
                    return {};
                }

                return { "quantityThreshold[gte]": "$quantity" };
            },
            getReadQueryFilter: (self, context): ReadQueryFilter => {
                if (!self.control.value) {
                    return {};
                }

                return { quantityThreshold: { gte: "$quantity" } };
            },
        } as IFilter<CheckBox, IGoodsFilterContext>,
    ];
};
