import { IGood, IGoodMoveManyDto, IStore } from "@lib";
import { Control, Select, TextBox } from "@core/components/alt-ui/controls";
import { Footer } from "@core/controls/footer.control";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Table } from "@core/components/alt-ui/table";

export interface IGoodMoveManyModalContext {
    stores: IStore[];
    goods: IGood[];
    moveHandler: (dto: IGoodMoveManyDto) => Promise<boolean>;
}

type GoodItem = {
    good: IGood;
    quantity: number;
};

export class GoodMoveManyModal extends Modal2<IGoodMoveManyModalContext, boolean | undefined> {
    private cbStore!: Select<IStore>;
    private tblGoodsTable!: Table<GoodItem>;
    private ftFooter!: Footer;

    private context?: IGoodMoveManyModalContext;

    public constructor() {
        super("good-move-many-modal", "Перемещение товаров");
        this.initializeControls();
    }

    public show(context: IGoodMoveManyModalContext): Promise<boolean | undefined> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStore = new Select<IStore>();
        this.cbStore.id = "good-move-many.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = [];
        this.cbStore.textField = s => s.info.name;
        this.cbStore.descriptionField = s => s.info.description;

        this.tblGoodsTable = new Table<GoodItem>();
        this.tblGoodsTable.id = "good-move-many.goods";
        this.tblGoodsTable.columns = [
            {
                title: "Наименование",
                classHeader: "text-left text-xs",
                classCell: "text-left",
                cell: item => item.good.info.name,
            },
            {
                title: "Количество",
                classHeader: "text-xs",
                cell: item => {
                    const tb = new TextBox();
                    tb.class = "mx-auto";
                    tb.style = "width: 7rem; text-align-last: right;";
                    tb.id = `good-move-many.good-${item.good.id}`;
                    tb.validation = `required|numeric|unsigned|min_value:1|max_value:${item.good.info.quantity}`;
                    tb.text = item.quantity.toString();
                    tb.addTextChangedHandler((s, e) => {
                        const quantity = parseInt(e.text);
                        if (isNaN(quantity)) {
                            return;
                        }

                        item.quantity = quantity;
                    });
                    return tb;
                },
            },
            {
                classCell: "text-nowrap",
                cell: item => `из ${item.good.info.quantity}`,
            },
        ];

        this.ftFooter = new Footer({
            okText: "Переместить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(context: IGoodMoveManyModalContext): void {
        const storeId = this.getSingleStoreId(context.goods);
        this.cbStore.items = context.stores.filter(store => store.id !== storeId);
        this.cbStore.selectedIndex = this.cbStore.items.length > 0 ? 0 : -1;

        this.tblGoodsTable.items = context.goods
            .filter(g => g.info.quantity > 0)
            .map(g => ({ good: g, quantity: g.info.quantity }));
    }

    private getSingleStoreId(goods: IGood[]): string | null {
        const stores = new Set<string>();
        for (const good of goods) {
            stores.add(good.store);
        }

        return stores.size === 1 ? stores.values().next().value : null;
    }

    public get footer(): Footer {
        return this.ftFooter;
    }

    public get controls(): Control[] {
        return [this.cbStore, this.tblGoodsTable];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide(false);
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid || !this.context?.moveHandler || !this.cbStore.selectedItem) {
            return;
        }

        const dto: IGoodMoveManyDto = {
            items: this.tblGoodsTable.items.map(i => ({ id: i.good.id, quantity: i.quantity })),
            storeId: this.cbStore.selectedItem.id,
        };

        const ok = await this.context.moveHandler(dto);

        if (ok) {
            this.hide(true);
        }
    }
}
