import { Vue, Component, Watch } from "vue-property-decorator";
import {
    IStore,
    IGood,
    IGoodCategory,
    IUser,
    ICompany,
    ITemplateLabel,
    ISupplier,
    IAccount,
    ITableColumn,
    ITableSort,
    IEmployee,
} from "@lib";
import { ITableFilter, TableType } from "@lib";
import { PermissionType, PermissionRight } from "@lib";
import AltTable, { TableApi } from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Modal2Component } from "@core/components/alt-ui/modal-2";
import { Button, Icon } from "@core/components/alt-ui/controls";
import { MultiDropdown } from "@core/controls/multi-dropdown/multi-dropdown";
import { FilterInfo, FilterModal } from "@/core/alt-forms/filter";
import { GoodModal, IGoodModalContext } from "./modals/good.modal";
import { GoodRegistrationModal } from "../good-registrations/modals/good-registration.modal";
import { getDefaultTableColumns, getDefaultTableActions } from "./goods-defaults-table";
import GoodsToolbar from "./goods-toolbar.vue";
import GoodsCategoryFilter from "./goods-category-filter.vue";
import { GoodHistoryModal } from "./modals/good-history.modal";
import { GoodMoveModal } from "./modals/good-move.modal";
import { GoodMoveManyModal } from "./modals/good-move-many.modal";
import { GoodPrintModal } from "./modals/good-print.modal";
import { GoodsImportModal } from "./modals/goods-import.modal";
import { GoodsExportModal } from "./modals/goods-export.modal";
import { IGoodsFilterContext, createFilters } from "./goods-filter";
import { StoresDataLoader, TableData } from "../stores-data-loader";

@Component({
    name: "goods",
    components: { GoodsToolbar, GoodsCategoryFilter, AltTable, ModalComponent, Modal2Component },
})
export default class Goods extends Vue {
    private DataLoader = new StoresDataLoader(this.$alt, this.$info, this.$settings);

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private employees: IEmployee[] = [];
    private stores: IStore[] = [];
    private accounts: IAccount[] = [];
    private categories: IGoodCategory[] = [];
    private suppliers: ISupplier[] = [];
    private selectedStores: IStore[] = [];
    private selectedCategories: IGoodCategory[] = [];
    private labelTemplates: ITemplateLabel[] = [];

    private goods: IGood[] = [];
    private goodsTotal: number = 0;

    private table: TableData = {
        api: new TableApi(),
        type: TableType.Good,
        page: 1,
        search: "",
        settings: null,
    };

    private filterInfo = new FilterInfo<IGoodsFilterContext>();

    private goodModal = new GoodModal();
    private goodRegistrationModal = new GoodRegistrationModal("good-registration-modal.stores");
    private goodHistoryModal = new GoodHistoryModal();
    private goodMoveModal = new GoodMoveModal();
    private goodMoveManyModal = new GoodMoveManyModal();
    private goodPrintModal = new GoodPrintModal();
    private goodsFilterModal = new FilterModal<IGoodsFilterContext>("goods-filter");
    private goodsImportModal = new GoodsImportModal();
    private goodsExportModal = new GoodsExportModal();

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get defaulActions(): any[] {
        return getDefaultTableActions(this);
    }

    private get can(): any {
        const secure = this.$secure;
        const stores = this.selectedStores;
        return {
            get create(): boolean {
                for (const store of stores) {
                    const valid = secure.check(PermissionType.Stores, store.id, PermissionRight.GoodsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
            get update(): Function {
                return (good: IGood): boolean => {
                    return secure.check(PermissionType.Stores, good.store, PermissionRight.GoodsUpdate);
                };
            },
            get delete(): Function {
                return (good: IGood): boolean => {
                    return secure.check(PermissionType.Stores, good.store, PermissionRight.GoodsDelete);
                };
            },
        };
    }

    private get columns(): any[] {
        return TableApi.prepareColumns(this.defaulColumns, this.table.settings?.columns ?? []);
    }

    private get filter(): ITableFilter[] {
        return this.table.settings?.filter ?? [];
    }

    public get categoryHashMap(): Record<string, { name: string; color: string }> {
        return this.categories.reduce<Record<string, { name: string; color: string }>>((a, v) => {
            a[v.id] = {
                name: v.name,
                color: v.color,
            };

            return a;
        }, {});
    }

    @Watch("selectedStores", { deep: true, immediate: true })
    private onSelectedStoresUpdate(): void {
        const headerControls = this.$info.ui.getHeaderControls();

        const hdrStores = headerControls.find(c => c.id === "stores.header-stores") as MultiDropdown<IStore>;
        if (hdrStores) {
            if (!this.$alt.compareArrays(hdrStores.selectedItems, this.selectedStores, e => e.id)) {
                hdrStores.selectedItems = this.selectedStores;
            }
        }
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.employees = await this.$info.getEmployees();
            this.stores = await this.$info.getStores();
            this.accounts = await this.$info.getAccounts();
            await this.DataLoader.init();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        [this.table.settings, this.categories, this.suppliers, this.labelTemplates] = await Promise.all([
            this.DataLoader.selectTableSettings(this.table.type),
            this.DataLoader.selectAllCategories(),
            this.DataLoader.selectSuppliers(),
            this.DataLoader.selectLabelTemplates(),
        ]);

        const filterStores = this.filter.filter(item => item.field === "store");
        this.selectedStores = this.stores.filter(s => !!filterStores.find(f => f.value === s.id));

        const filterCategories = this.filter.filter(item => item.field === "category");
        this.selectedCategories = this.categories.filter(c => !!filterCategories.find(f => f.value === c.id));

        this.filterInfo.init({
            filters: createFilters(),
            tfilters: this.filter,
            stores: this.stores,
            categories: this.categories,
        });

        if (this.selectedStores.length === 0 && this.stores[0]) {
            this.filterInfo.setValue("store", [this.stores[0].id]);
            await this.changeTableFilter(this.filterInfo);
        }

        this.initHeader();

        await this.selectData();
    }

    private initHeader(): void {
        const hdrStores = new MultiDropdown<IStore>();
        hdrStores.id = "stores.header-stores";
        hdrStores.items = this.stores;
        hdrStores.selectedItems = this.selectedStores;
        hdrStores.itemId = item => item.id;
        hdrStores.itemName = item => item.info.name;
        hdrStores.iconPackage = "feather";
        hdrStores.icon = "PackageIcon";
        hdrStores.locale = {
            Tooltip: "Выбрать склад",
            ButtonSelectOne: "Выбрать один",
            ButtonSelectMultiple: "Выбрать несколько",
            ButtonSelectAll: "Выбрать все",
            TextNotSelected: "Склад не выбран",
            TextSelectedAll: "Все склады",
            TextSelectedMultiple: "Выбрано:",
            TextSelectedMultipleForms: ["склад", "склада", "складов"],
        };
        hdrStores.addChangedHandler((s, e) => {
            const ids = e.items.map(store => store.id);

            this.filterInfo.setValue("store", ids);
            this.changeTableFilterAndRefresh(this.filterInfo);
        });

        const hdrRefresh = new Button();
        hdrRefresh.id = "stores.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => {
            this.$alt.longOperation(() => this.initData(), "Данные обновлены.");
        });

        this.$info.ui.setHeaderControls([hdrStores, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$alt.queryExits("new")) {
                this.showModalGoodRegistration();
                return;
            }

            const goodId = this.$alt.queryGetFirst("id");
            if (goodId) {
                const good = await this.DataLoader.getGood(goodId);
                this.showModalUpdate(good);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw e;
        }
    }

    public onCategoryFilerSelectAll(): void {
        this.filterInfo.setValue("category", []);
        this.changeTableFilterAndRefresh(this.filterInfo);
    }

    public onCategoryFilterSelect(categoryValue: string): void {
        this.filterInfo.setValue("category", [categoryValue]);
        this.changeTableFilterAndRefresh(this.filterInfo);
    }

    public async changeTableColumns(columns: ITableColumn[]): Promise<void> {
        this.$alt.longOperation(async () => {
            const settings = await this.DataLoader.updateTableColumns(this.table.type, columns);

            if (!settings) {
                this.table.settings = settings;
            }
        });
    }

    public async changeTableSort(sort: ITableSort[]): Promise<void> {
        this.$alt.longOperation(async () => {
            const settings = await this.DataLoader.updateTableSort(this.table.type, sort);

            if (!settings) {
                this.table.settings = settings;
                await this.selectData();
            }
        });
    }

    public async changeTableLimit(limit: number, page: number): Promise<void> {
        this.$alt.longOperation(async () => {
            const settings = await this.DataLoader.updateTableLimit(this.table.type, limit);

            if (!settings) {
                this.table.settings = settings;
                this.table.page = page;
                await this.selectData();
            }
        });
    }

    public async changeTablePage(page: number): Promise<void> {
        this.$alt.longOperation(async () => {
            this.table.page = page;
            await this.selectData();

            // TODO: добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        });
    }

    private async changeTableFilter(filterInfo: FilterInfo<IGoodsFilterContext>): Promise<boolean> {
        try {
            this.filterInfo = filterInfo;
            const filter = this.filterInfo.getTableFilter();
            this.table.settings = await this.DataLoader.updateTableFilter(this.table.type, filter);

            const filterStores = this.filter.filter(item => item.field === "store");
            this.selectedStores = this.stores.filter(s => !!filterStores.find(f => f.value === s.id));

            const filterCategories = this.filter.filter(item => item.field === "category");
            this.selectedCategories = this.categories.filter(c => !!filterCategories.find(f => f.value === c.id));
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        }
    }

    private async changeTableFilterAndRefresh(filterInfo: FilterInfo<IGoodsFilterContext>): Promise<void> {
        this.$alt.longOperation(async () => {
            const success = await this.changeTableFilter(filterInfo);

            if (success) {
                await this.selectData();
            }
        });
    }

    public async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData();
        } catch (e: any) {
            this.goods = [];
            this.goodsTotal = 0;
        }
    }

    public async showModalGoodRegistration(): Promise<void> {
        const labelTemplatesIds = await this.$settings.getLabelsGoodRegistrationCreate();
        const labelTemplatesSelected = this.labelTemplates.filter(d => labelTemplatesIds.includes(d.id));
        const deductFromAccount = await this.$settings.getDeductFromAccountGoodRegistrationCreate();
        const accountId = await this.$settings.getAccountGoodRegistrationCreate();
        const barcodesFeature = await this.DataLoader.getBarcodesFeature();

        const success = await this.goodRegistrationModal.show({
            companyId: this.company.id,
            userId: this.user.id,
            stores: this.stores,
            store: this.selectedStores[0],
            accounts: this.accounts,
            suppliers: this.suppliers,
            categories: this.categories,
            labelTemplates: this.labelTemplates,
            labelTemplatesSelected: labelTemplatesSelected,
            showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
            message: this.$alt.message,
            isDeductFromAccountSelected: deductFromAccount,
            selectedAccountId: accountId,
            barcodesFeature: barcodesFeature,
            barcodesUseCase: this.$alt.system.usecase.createCompanyFeatureBarcodesUseCase(),
            searchGoods: (search, storeId) => this.DataLoader.searchGoods(search, storeId),
            createHandler: (dto, labels, deductFromAccount, account) =>
                this.$alt.longOperation(async () => {
                    const registration = await this.$alt.system.usecase
                        .createGoodRegistrationUseCase()
                        .create(this.company.id, dto);
                    this.DataLoader.saveSelectedLabels(labels);
                    this.DataLoader.saveSelectedDeductFromAccount(deductFromAccount);
                    if (deductFromAccount && account) {
                        this.DataLoader.saveSelectedAccount(account);
                    }
                    this.DataLoader.printLabelsForGoodRegistration(labels, registration);
                }, "Оприходование успешно создано."),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    // public async showModalCreate(): Promise<void> {
    //     const context: IGoodModalContext = {
    //         company: this.company,
    //         stores: this.stores,
    //         selectedStores: this.selectedStores,
    //         categories: this.categories,
    //         showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
    //         createHandler: (storeId, dto) =>
    //             this.$alt.longOperation(() => this.DataLoader.createGood(storeId, dto), "Товар успешно создан."),
    //     };

    //     const success = await this.goodModal.show(context);

    //     if (success) {
    //         await this.$alt.longOperation(() => this.selectData());
    //     }
    // }

    public async showModalUpdate(good: IGood): Promise<void> {
        const context: IGoodModalContext = {
            company: this.company,
            stores: this.stores,
            selectedStores: this.selectedStores,
            categories: this.categories,
            showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
            templates: this.labelTemplates,
            good: good,
            updateHandler: (good, dto) =>
                this.$alt.longOperation(() => this.DataLoader.updateGood(good, dto), "Товар успешно изменён."),
            historyHandler: good => this.showModalHistory(good),
            moveHandler: good => this.showModalMove(good),
            printHandler: (template, good) => this.showModalPrint(template, [good]),
        };

        const success = await this.goodModal.show(context);

        if (success) {
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    public async showModalHistory(good: IGood): Promise<void> {
        await this.goodHistoryModal.show({
            good: good,
            employees: this.employees,
            loadHandler: (offset, limit) =>
                this.$alt.longOperationFunc(() => this.DataLoader.getGoodHistotry(good.id, offset, limit)),
        });
    }

    public async showModalMove(good: IGood): Promise<void> {
        const success = await this.goodMoveModal.show({
            good: good,
            stores: this.stores,
            moveHandler: dto =>
                this.$alt.longOperation(() => this.DataLoader.moveGood(good, dto), "Товар успешно перемещён."),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    public async showModalMoveMany(goods: IGood[]): Promise<void> {
        const success = await this.goodMoveManyModal.show({
            goods: goods,
            stores: this.stores,
            moveHandler: dto =>
                this.$alt.longOperation(() => this.DataLoader.moveGoods(dto), "Товары успешно перемещены."),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    public showModalPrint(template: ITemplateLabel, goods: IGood[]): Promise<void> {
        return this.goodPrintModal.show({
            goods,
            template,
            dataLoader: this.DataLoader,
        });
    }

    public async showModalFilter(): Promise<void> {
        const success = await this.goodsFilterModal.show({
            filters: createFilters(),
            tfilters: this.filter,
            stores: this.stores,
            categories: this.categories,
            saveHandler: info => this.$alt.longOperationBool(() => this.changeTableFilter(info)),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    public async showModalImport(): Promise<void> {
        const success = await this.goodsImportModal.show({
            stores: this.stores,
            storeId: this.selectedStores.length > 0 ? this.selectedStores[0].id : null,
            importHandler: dto =>
                this.$alt.longOperationBool(async () => {
                    if (!dto.file) {
                        this.$alt.toast.warning("Файл не выбран.");
                        return false;
                    }

                    await this.DataLoader.importGoods(dto.storeId, dto.file);
                    return true;
                }, "Данные успешно загружены."),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    public async showModalExport(): Promise<void> {
        await this.goodsExportModal.show({
            stores: this.stores,
            storeIds: this.selectedStores.map(s => s.id),
            categories: this.categories.filter(c => !c.archived),
            exportHandler: dto =>
                this.$alt.longOperation(() => this.DataLoader.exportGoods(dto.filter), "Данные успешно выгружены."),
        });
    }

    public async confirmDelete(good: IGood): Promise<void> {
        const answer = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить товар: "${good.info.name}"?`,
            "Удаление товара",
            { acceptText: "Удалить" },
        );

        if (answer) {
            await this.$alt.longOperation(async () => {
                await this.DataLoader.deleteGood(good);
                this.$alt.toast.success("Товар успешно удалён.");
                await this.selectData();
            });
        }
    }

    public async confirmDeleteMany(goods: IGood[]): Promise<void> {
        const answer = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить ${goods.length} товаров?`,
            "Удаление товаров",
            { acceptText: "Удалить" },
        );

        if (answer) {
            await this.$alt.longOperation(async () => {
                await this.DataLoader.deleteGoods(goods);
                this.$alt.toast.success("Товары успешно удалены.");
                await this.selectData();
            });
        }
    }

    private async selectData(): Promise<void> {
        try {
            const result = await this.DataLoader.selectGoodsForTable(this.table);
            this.goods = result.data;
            this.goodsTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить товары:\n${e.message}`);
        }
    }
}
