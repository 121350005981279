import { Currency, IGood, Locale } from "@lib";
import { IAltTableAction, IAltTableColumn } from "@/core/components/alt-table";
import { Localizer } from "@/i18n/localizer";

export function getDefaultTableColumns(context: any): IAltTableColumn<IGood>[] {
    return [
        // {
        //     colId: "checkbox",
        //     headerName: "Флаг",
        //     width: 70,
        //     hide: false,
        //     headerClass: "text-transparent",
        //     headerCheckboxSelection: true,
        //     checkboxSelection: true,
        //     suppressMovable: true,
        //     resizable: false,
        //     static: true,
        // },
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            minWidth: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            hide: false,
            headerClass: "text-transparent",
            headerCheckboxSelection: true,
            checkboxSelection: true,

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (good: IGood) => context.can.update(good),
                            click: (good: IGood) => context.showModalUpdate(good),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (good: IGood) => context.can.delete(good),
                            click: (good: IGood) => context.confirmDelete(good),
                        },
                    ],
                };
            },
        },
        {
            colId: "store",
            field: "info.store",
            headerName: "Склад",
            sortable: true,
            filter: true,
            width: 175,
            hide: true,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: params => {
                return {
                    name: params.data.storeRef?.info.name,
                };
            },
        },
        {
            colId: "categories",
            field: "info.categories",
            headerName: "Категории",
            sortable: false,
            filter: true,
            width: 175,
            hide: false,

            cellRendererFramework: "CellRendererChips",
            cellRendererParams: params => {
                const ids = params.value as string[] | undefined;

                if (!ids?.length) {
                    return {
                        chips: [],
                    };
                }

                return {
                    chips: ids
                        .map(id => {
                            const category = context.categoryHashMap[id] ?? null;

                            if (!category) {
                                return null;
                            }

                            return {
                                text: category.name,
                                color: category.color || "#82868b",
                            };
                        })
                        .filter(id => id),
                };
            },
        },
        {
            colId: "sku",
            field: "info.sku",
            headerName: "Артикул",
            sortable: true,
            filter: true,
            width: 150,
            hide: false,
        },
        {
            colId: "name",
            field: "info.name",
            headerName: "Наименование",
            sortable: true,
            filter: true,
            width: 500,
            hide: false,
        },
        {
            colId: "description",
            field: "info.description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 500,
            hide: true,
        },
        {
            colId: "cost",
            field: "info.cost",
            headerName: "Себестоимость",
            sortable: true,
            filter: true,
            width: 185,
            hide: false,

            cellRenderer: params => {
                const currency = params.data?.storeRef?.info?.currency ?? Currency.RUB;
                const locale = params.data?.storeRef?.info?.locale ?? Locale.RU;
                return Localizer.currency(params.value, currency, locale);
            },
        },
        {
            colId: "price",
            field: "info.price",
            headerName: "Цена продажи",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: params => {
                const currency = params.data?.storeRef?.info?.currency ?? Currency.RUB;
                const locale = params.data?.storeRef?.info?.locale ?? Locale.RU;
                return Localizer.currency(params.value, currency, locale);
            },
        },
        {
            colId: "quantity",
            field: "info.quantity",
            headerName: "Количество",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: params => {
                const good = params.data as IGood;

                if (good?.quantityThreshold === undefined) {
                    return params.value;
                }

                if (good.info.quantity > good.quantityThreshold) {
                    return params.value;
                }

                return `<span class="bg-danger text-white font-semibold rounded-md px-1 py-0.5">${params.value}</span>`;
            },
        },
        {
            colId: "tags",
            field: "tags",
            headerName: "Метки",
            filter: true,
            width: 150,
            hide: false,

            cellRendererFramework: "CellRendererTags",
            cellRendererParams: params => {
                const tags = params.value;
                if (!tags) {
                    return;
                }

                return {
                    tags,
                    handler: (t: string) => context.table.api.search(t),
                };
            },
        },
    ];
}

export function getDefaultTableActions(context: any): IAltTableAction<IGood>[] {
    const actions: IAltTableAction<IGood>[] = [];

    for (const template of context.labelTemplates) {
        actions.push({
            name: `Печатать ${template.name}`,
            icon: "PrinterIcon",
            click: (goods: IGood[]) => context.showModalPrint(template, goods),
        });
    }

    if (context.labelTemplates.length > 0) {
        actions.push({
            type: "divider",
        });
    }

    if (context.stores.length > 1) {
        actions.push(
            {
                name: "Переместить",
                icon: "ShareIcon",
                click: (goods: IGood[]) => context.showModalMoveMany(goods),
            },
            {
                type: "divider",
            },
        );
    }

    actions.push({
        name: "Удалить всё",
        icon: "Trash2Icon",
        click: (goods: IGood[]) => context.confirmDeleteMany(goods),
    });

    return actions;
}
