import { ISale, ISaleGood, DiscountType, IShop, ITemplateDocument, Locale, Currency } from "@lib";
import { WarrantyUtils } from "@/utils/types/warranty.utils";
import { IMacroReplacer } from "../macro";
import * as filters from "@/filters";

export class SaleTableMacroReplacer implements IMacroReplacer<ITemplateDocument> {
    private readonly sale: ISale;
    private readonly styles: any;
    private readonly currency: Currency;
    private readonly locale: Locale;

    public constructor(sale: ISale) {
        this.sale = sale;
        this.styles = {
            table: "width: 100%; border: 0px solid black; border-collapse: collapse;",
            tr: "border: 1px solid black;",
            th: "border: 1px solid black; font-weight: bold;",
            td: "border: 1px solid black; padding: 1px 6px;",
            tdResultTitle: "text-align: right; border: 0px; padding: 1px 6px; font-weight: bold;",
            tdResultValue: "text-align: right; border: 1px solid black; padding: 1px 6px; font-weight: bold;",
        };

        this.currency = sale?.shopRef?.info?.currency ?? Currency.RUB;
        this.locale = sale?.shopRef?.info?.locale ?? Locale.RU;
    }

    public replace(model: ITemplateDocument): string {
        return this.replaceSimple(model.template, model);
    }

    public replaceSimple(template: string, model?: ITemplateDocument): string {
        const regexp = /%\(таблица\s*=\s*(?<table>([^~]|~(?!~))*)~~\)/giu;
        // \s* - любое количество пробельных символов
        // [^~] - любой символ, кроме тильды
        // ~(?!~) - тильда, если сразу за ней идёт не тильда
        // (?<table>([^~]|~(?!~))*) - все символы, кроме двух тильд подряд записать в переменную table

        /*const results = template.matchAll(regexp);
        for (const result of results) {
            if (!result.groups) break;

            const json = result.groups["table"];
            alert(json);

            const obj = JSON.parse(json);
            alert(JSON.stringify(obj));
        }*/

        return template.replace(regexp, (match: string, tableJson: string) => {
            try {
                // убираем html-тэги, которые могут присутствовать из-за переноса строк
                tableJson = tableJson.replaceAll(/<[^>]+>/gi, "");
                tableJson = tableJson.replaceAll("&nbsp;", "");
                const table = JSON.parse(tableJson);
                return this.makeTable(table);
            } catch {
                //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });
    }

    private makeTable(table: any): string {
        const rows = table["строки"] as any[];
        const columns = table["колонки"] as any[];
        const total = table["итого"] as any[];

        const summary = {
            number: 0,
            quantity: 0,
            sum: 0,
            discount: this.sale.info?.discount?.value ?? 0,
        };

        let result = `<table style="${this.styles.table}"><tbody>`;
        result += this.makeTableHeader(columns);
        result += this.makeTableRows(rows, summary, columns);
        result += this.makeTableTotal(total, summary, columns);
        result += "</tbody></table>";
        return result;
    }

    private makeTableHeader(columns: any[]): string {
        let result = "";
        result += `<tr style="${this.styles.tr}">`;
        for (const column of columns) {
            const title = column["заголовок"];
            result += `<th style="${this.styles.th}">${title}</th>`;
        }
        result += "</tr>";
        return result;
    }

    private makeTableRows(rows: any[], summary: any, columns: any[]): string {
        let result = "";
        for (const row of rows) {
            const field = row["поле"];
            const hidden = row["скрыто"] ? row["скрыто"] === "да" : false;

            const items = this.getGoods(field);
            if (items) {
                for (const item of items) {
                    summary["quantity"] += item.quantity;
                    summary["sum"] += item.quantity * item.price;

                    if (hidden) {
                        continue;
                    }

                    summary["number"] += 1;
                    result += this.makeTableRow(item, summary["number"], columns);
                }
            }
        }
        return result;
    }

    private makeTableRow(item: ISaleGood, number: number, columns: any[]): string {
        let result = "";
        result += `<tr style="${this.styles.tr}">`;
        for (const column of columns) {
            const field = column["поле"] ?? "";
            const style = column["стиль"] ?? "";
            const value = this.getGoodValue(field, item, number);
            result += `<td style="${this.styles.td} ${style}">${value}</td>`;
        }
        result += "</tr>";
        return result;
    }

    private makeTableTotal(total: any[], summary: any, columns: any[]): string {
        let result = "";
        for (const item of total) {
            const field = item["поле"];
            const title = item["заголовок"];
            const condition = item["условие"] ?? "да";
            const value = this.getTotalValue(field, summary);

            if (!this.evaluateConditions(condition, summary)) {
                continue;
            }

            result += "<tr>";
            result += `<td colspan="${columns.length - 1}" style="${this.styles.tdResultTitle}">${title}:</td>`;
            result += `<td style="${this.styles.tdResultValue}">${filters.money.moneyFormat(value, {
                locale: this.locale,
            })}</td>`;
            result += "</tr>";
        }
        return result;
    }

    private getGoods(field: string): ISaleGood[] | undefined {
        switch (field.toLowerCase()) {
            case "товары":
                return this.sale.goods;
        }

        throw new Error(`Неизвестный тип поля ${field}.`);
    }

    private getGoodValue(field: string, good: ISaleGood, number: number): string {
        switch (field.toLowerCase()) {
            case "номер":
                return number.toString();
            case "наименование":
                return good.name;
            case "описание":
                return good.description ?? "";
            case "гарантия":
                return WarrantyUtils.toString(good.warranty);
            case "количество":
                return good.quantity.toString();
            case "себестоимость":
                return filters.money.moneyFormat(good.cost, { locale: this.locale });
            case "цена":
                return filters.money.moneyFormat(good.price, { locale: this.locale });
            case "сумма":
                return filters.money.moneyFormat(good.quantity * good.price, { locale: this.locale });
        }

        throw new Error(`Неизвестный тип поля ${field}.`);
    }

    private getTotalValue(field: string, summary: any): number {
        const discoutValue = this.getDiscountValue(summary["sum"]);
        switch (field.toLowerCase()) {
            case "сумма":
                return summary["sum"];
            case "скидка":
                return discoutValue;
            case "сумма-скидка":
                return summary["sum"] - discoutValue;
        }

        throw new Error(`Неизвестный тип поля ${field}.`);
    }

    private getDiscountValue(sum: number): number {
        if (!this.sale.info?.discount) {
            return 0.0;
        }

        const discount = this.sale.info.discount.value ?? 0.0;
        switch (this.sale.info.discount.type) {
            case DiscountType.Fixed:
                return discount;
            case DiscountType.Percent:
                return (sum * discount) / 100;
        }

        return 0.0;
    }

    private evaluateConditions(condition: string, summary: any): boolean {
        switch (condition.toLowerCase()) {
            case "да":
                return true;
            case "нет":
                return false;
            case "скидка == 0":
                return summary["discount"] === 0;
            case "скидка != 0":
                return summary["discount"] !== 0;
        }

        return false;
    }
}
