import { IOrder, Currency, Locale } from "@lib";
import { IIterableMacro, IMacro } from "../macro";
import { WarrantyUtils } from "@/utils/types/warranty.utils";
import * as filters from "@/filters";

export interface IOrderTableContext {
    order: IOrder;
    currency: Currency;
    locale: Locale;
    info: IOrderMacroInfo;
}

export interface IOrderTableIterableContext {
    order: IOrder;
    locale: Locale;
    meta: {
        iterator: number;
    };
}

export interface IOrderMacroInfo {
    works: {
        quantity: number;
        sum: number;
    };

    materials: {
        quantity: number;
        expenses: number;
        sum: number;
    };

    sum: number;
    payments: number;
    discount: number;
}

export const OrderTable2IterableMacroList: IIterableMacro<IOrderTableIterableContext>[] = [
    {
        alias: ["р.номер", "работы.номер"],
        open: (context, args) => {
            const value = context.order.works.length ? (context.meta.iterator + 1).toString() : "";

            return {
                hasNextRow: !!context.order.works[context.meta.iterator + 1],
                value,
            };
        },
    },
    {
        alias: ["р.наименование", "работы.наименование"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.name : "",
            };
        },
    },
    {
        alias: ["р.гарантия", "работы.гарантия"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toString(current.warranty) : "",
            };
        },
    },
    {
        alias: ["р.дата-гарантии", "работы.дата-гарантии", "р.датагарантии", "работы.датагарантии"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toMaxDateString([current.warranty]) : "",
            };
        },
    },
    {
        alias: ["р.количество", "работы.количество"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.quantity.toString() : "",
            };
        },
    },
    {
        alias: ["р.себестоимость", "работы.себестоимость"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value:
                    current && current.cost ? filters.money.moneyFormat(current.cost, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["р.цена", "работы.цена"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? filters.money.moneyFormat(current.price, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["р.цена-с-материалами", "работы.цена-с-материалами"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            let materialsPrice = 0;
            const materials = context.order.materials.filter(m => m.work === current.id);
            for (const material of materials) {
                materialsPrice += material.price;
            }
            materialsPrice /= current.quantity;

            return {
                hasNextRow: !!next,
                value: current
                    ? filters.money.moneyFormat(current.price + materialsPrice, { locale: context.locale })
                    : "",
            };
        },
    },
    {
        alias: ["р.сумма", "работы.сумма"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current
                    ? filters.money.moneyFormat(current.quantity * current.price, { locale: context.locale })
                    : "",
            };
        },
    },
    {
        alias: ["р.сумма-с-материалами", "работы.сумма-с-материалами"],
        open: (context, args) => {
            const current = context.order.works[context.meta.iterator];
            const next = context.order.works[context.meta.iterator + 1];
            const workSum = current.quantity * current.price;

            let materialsSum = 0;
            const materials = context.order.materials.filter(m => m.work === current.id);
            for (const material of materials) {
                materialsSum += material.price;
            }

            return {
                hasNextRow: !!next,
                value: current ? filters.money.moneyFormat(workSum + materialsSum, { locale: context.locale }) : "",
            };
        },
    },

    // Материалы

    {
        alias: ["м.номер", "материалы.номер"],
        open: (context, args) => {
            const value = context.order.materials.length ? (context.meta.iterator + 1).toString() : "";

            return {
                hasNextRow: !!context.order.materials[context.meta.iterator + 1],
                value,
            };
        },
    },
    {
        alias: ["м.наименование", "материалы.наименование"],
        open: (context, args) => {
            const current = context.order.materials[context.meta.iterator];
            const next = context.order.materials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.name : "",
            };
        },
    },
    {
        alias: ["м.гарантия", "материалы.гарантия"],
        open: (context, args) => {
            const current = context.order.materials[context.meta.iterator];
            const next = context.order.materials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toString(current.warranty) : "",
            };
        },
    },
    {
        alias: ["м.дата-гарантии", "материалы.дата-гарантии", "м.датагарантии", "материалы.датагарантии"],
        open: (context, args) => {
            const current = context.order.materials[context.meta.iterator];
            const next = context.order.materials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toMaxDateString([current.warranty]) : "",
            };
        },
    },
    {
        alias: ["м.количество", "материалы.количество"],
        open: (context, args) => {
            const current = context.order.materials[context.meta.iterator];
            const next = context.order.materials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.quantity.toString() : "",
            };
        },
    },
    {
        alias: ["м.себестоимость", "материалы.себестоимость"],
        open: (context, args) => {
            const current = context.order.materials[context.meta.iterator];
            const next = context.order.materials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? filters.money.moneyFormat(current.cost, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["м.цена", "материалы.цена"],
        open: (context, args) => {
            const current = context.order.materials[context.meta.iterator];
            const next = context.order.materials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? filters.money.moneyFormat(current.price, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["м.сумма", "материалы.сумма"],
        open: (context, args) => {
            const current = context.order.materials[context.meta.iterator];
            const next = context.order.materials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current
                    ? filters.money.moneyFormat(current.quantity * current.price, { locale: context.locale })
                    : "",
            };
        },
    },

    // Работы и материалы

    {
        alias: ["рм.номер", "работыматериалы.номер"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);
            const value = worksMaterials.length ? (context.meta.iterator + 1).toString() : "";

            return {
                hasNextRow: !!worksMaterials[context.meta.iterator + 1],
                value,
            };
        },
    },
    {
        alias: ["рм.наименование", "работыматериалы.наименование"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);

            const current = worksMaterials[context.meta.iterator];
            const next = worksMaterials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.name : "",
            };
        },
    },
    {
        alias: ["рм.гарантия", "работыматериалы.гарантия"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);

            const current = worksMaterials[context.meta.iterator];
            const next = worksMaterials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toString(current.warranty) : "",
            };
        },
    },
    {
        alias: ["рм.дата-гарантии", "работыматериалы.дата-гарантии", "рм.датагарантии", "работыматериалы.датагарантии"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);

            const current = worksMaterials[context.meta.iterator];
            const next = worksMaterials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toMaxDateString([current.warranty]) : "",
            };
        },
    },
    {
        alias: ["рм.количество", "работыматериалы.количество"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);

            const current = worksMaterials[context.meta.iterator];
            const next = worksMaterials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.quantity.toString() : "",
            };
        },
    },
    {
        alias: ["рм.себестоимость", "работыматериалы.себестоимость"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);

            const current = worksMaterials[context.meta.iterator];
            const next = worksMaterials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value:
                    current && current.cost ? filters.money.moneyFormat(current.cost, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["рм.цена", "работыматериалы.цена"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);

            const current = worksMaterials[context.meta.iterator];
            const next = worksMaterials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? filters.money.moneyFormat(current.price, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["рм.сумма", "работыматериалы.сумма"],
        open: (context, args) => {
            const worksMaterials = context.order.works.concat(context.order.materials as any[]);

            const current = worksMaterials[context.meta.iterator];
            const next = worksMaterials[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current
                    ? filters.money.moneyFormat(current.quantity * current.price, { locale: context.locale })
                    : "",
            };
        },
    },
];

export const OrderTable2MacroList: IMacro<IOrderTableContext>[] = [
    // работы

    {
        alias: ["р.итого", "работы.итого"],
        open: (context, args) => {
            const value = context.info.works.sum;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["р.скидка", "работы.скидка"],
        open: (context, args) => {
            const value = context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["р.итого-без-скидки", "работы.итого-без-скидки"],
        open: (context, args) => {
            const value = context.info.works.sum - context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["р.предоплата", "работы.предоплата"],
        open: (context, args) => {
            const value = context.info["payments"];
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["р.к-оплате", "работы.к-оплате"],
        open: (context, args) => {
            const value = context.info.works.sum - context.info.discount - context.info.payments;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },

    // материалы

    {
        alias: ["м.итого", "материалы.итого"],
        open: (context, args) => {
            const value = context.info.materials.sum;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["м.скидка", "материалы.скидка"],
        open: (context, args) => {
            const value = context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["м.сумма-без-скидки", "материалы.сумма-без-скидки"],
        open: (context, args) => {
            const value = context.info.materials.sum - context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["м.предоплата", "материалы.предоплата"],
        open: (context, args) => {
            const value = context.info.payments;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["м.к-оплате", "материалы.к-оплате"],
        open: (context, args) => {
            const value = context.info.materials.sum - context.info.discount - context.info.payments;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },

    // работы и материалы

    {
        alias: ["рм.итого", "работыматериалы.итого"],
        open: (context, args) => {
            const value = context.info.sum;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["рм.скидка", "работыматериалы.скидка"],
        open: (context, args) => {
            const value = context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["рм.итого-без-скидки", "работыматериалы.итого-без-скидки"],
        open: (context, args) => {
            const value = context.info.sum - context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["рм.предоплата", "работыматериалы.предоплата"],
        open: (context, args) => {
            const value = context.info.payments;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["рм.к-оплате", "работыматериалы.к-оплате"],
        open: (context, args) => {
            const value = context.info.sum - context.info.discount - context.info.payments;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
];
